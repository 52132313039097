import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconChevronRight: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M29.6 97.6l44.2-44.4c.9-.9 1.3-2.1 1.3-3.3 0-1.2-.5-2.4-1.3-3.3L29.6 2.4c-1.1-1.1-2.8-1.1-3.9 0-.5.5-.8 1.2-.8 1.9s.3 1.4.8 1.9l43.6 43.6-43.6 43.9c-1.1 1.1-1.1 2.8 0 3.9s2.8 1.1 3.9 0z" />
    </svg>
  );
};

export default IconChevronRight;
