import React from 'react';

const OutboundLink: React.FC<React.HTMLProps<HTMLAnchorElement>> = ({
  children,
  href,
  ...props
}) => {
  return (
    <a {...props} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default OutboundLink;
