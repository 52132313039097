import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { SerializedStyles, css } from '@emotion/react';

import styled, { baseline, scale, hachureStyles, BREAKPOINTS } from '../styled';

import CenteredBox from './CenteredBox';

const Background = styled.div`
  ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
`;

const Layout = styled(CenteredBox)`
  position: relative;
  padding-top: ${scale(2)};
  padding-bottom: ${scale(2)};
`;

const Heading = styled.h1`
  margin-top: 0;
  margin-bottom: ${scale(1)};
`;

const BreadcrumbList = styled.ol`
  display: block;
  margin-bottom: 0;
  padding-left: 0;

  ${baseline(12, 1, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }
`;

const BreadcrumbListItem = styled.li`
  display: inline;

  &:before {
    display: none;
  }

  &:not(:last-child):after {
    content: ' > ';
  }
`;

const BreadcrumbListItemLink = styled(Link)`
  color: #000;
  font-weight: normal;
  text-decoration: none;

  &:hover {
    color: #000;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const PageTitle: React.FC = ({ children, ...props }) => {
  return (
    <Background>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Accueil',
                item: `https://www.greencourse.fr/`,
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: children,
              },
            ],
          })}
        </script>
      </Helmet>
      <Layout {...props}>
        <Heading>{children}</Heading>
        <BreadcrumbList>
          <BreadcrumbListItem>
            <BreadcrumbListItemLink to="/">Accueil</BreadcrumbListItemLink>
          </BreadcrumbListItem>
          <BreadcrumbListItem>
            <span
              css={css`
                font-weight: bold;
              `}
            >
              {children}
            </span>
          </BreadcrumbListItem>
        </BreadcrumbList>
      </Layout>
    </Background>
  );
};

export default PageTitle;
