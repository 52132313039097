import styled, { scale } from '../styled';

export enum CenteredBoxSizes {
  S = 's',
  M = 'm',
}

export enum CenteredBoxMaxWidth {
  S = 36,
  M = 45,
}

const CenteredBox = styled.div<{ as?: React.ElementType; size?: CenteredBoxSizes }>`
  max-width: ${({ size }): string =>
    size === CenteredBoxSizes.S ? scale(CenteredBoxMaxWidth.S) : scale(CenteredBoxMaxWidth.M)};
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${scale(1)};
  padding-right: ${scale(1)};
`;

export default CenteredBox;
