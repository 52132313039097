import React from 'react';
import { SerializedStyles } from '@emotion/react';

import styled, { scale, hachureStyles, BREAKPOINTS } from '../styled';

import CenteredBox from './CenteredBox';
import { IconPackage, IconEnvelope, IconSpinner } from './icons';
import ButtonLink from './ButtonLink';

const Layout = styled(CenteredBox)`
  padding-top: ${scale(2)};
  padding-bottom: ${scale(2)};

  @media (min-width: ${BREAKPOINTS.s}) {
    padding-top: ${scale(4)};
    padding-bottom: ${scale(4)};
  }
`;

const ServicesList = styled.div<{ blockCount: number }>`
  margin: ${scale(-2)};

  @media (min-width: ${BREAKPOINTS.s}) {
    display: flex;
    margin: ${scale(-1)};
  }

  > * {
    @media (min-width: ${BREAKPOINTS.s}) {
      width: ${({ blockCount }): string => `${100 / blockCount}%`};
    }
  }
`;

const ServiceLayout = styled.div`
  position: relative;
  margin: ${scale(2)};
  padding-top: ${scale(1)};
  padding-bottom: ${scale(2)};
  padding-left: ${scale(1)};
  padding-right: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    padding: ${scale(2)};
    margin: ${scale(1)};

    display: flex;
    flex-direction: column;
  }

  &:before {
    content: '';
    ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

const Icon = styled.svg<{ as?: React.ElementType }>`
  display: block;
  margin-top: 0;
  margin-bottom: ${scale(1)};
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${BREAKPOINTS.s}) {
    order: 2;
    margin-bottom: 0;
  }
`;

const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: 0;

  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.s}) {
    flex-grow: 1;
    order: 1;
    margin-bottom: ${scale(1)};
  }
`;

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  left: ${scale(1)};
  right: ${scale(1)};
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateY(50%);
`;

const Link = styled(ButtonLink)`
  padding: calc(var(--fontSize) * var(--lineHeight) / 4 * 1px - 2px) ${scale(1.25)};
`;

const Title = styled.h2`
  margin-bottom: ${scale(2)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-bottom: ${scale(1)};
  }
`;

export enum ServiceBlockTypes {
  MailService = 'MailService',
  PackageDelivery = 'PackageDelivery',
  UrgentTransport = 'UrgentTransport',
}

const ServiceItem: React.FC<{ icon: React.ElementType; to: string }> = ({
  children,
  to,
  icon,
  ...props
}) => {
  const isMobile =
    typeof window !== 'undefined' && window.matchMedia(`(min-width: ${BREAKPOINTS.s})`).matches
      ? /* istanbul ignore next */
        3
      : 2;

  return (
    <ServiceLayout {...props}>
      <Icon as={icon} scale={isMobile} />
      <Heading>{children}</Heading>
      <Actions>
        <Link to={to}>En savoir plus</Link>
      </Actions>
    </ServiceLayout>
  );
};

const ServicesBlock: React.FC<{
  title?: string;
  displayedBlockTypes?: ServiceBlockTypes[];
}> = ({
  title,
  displayedBlockTypes = [
    ServiceBlockTypes.MailService,
    ServiceBlockTypes.PackageDelivery,
    ServiceBlockTypes.UrgentTransport,
  ],
  ...props
}) => {
  return (
    <Layout {...props} as="section">
      {title && <Title>{title}</Title>}
      <ServicesList blockCount={displayedBlockTypes.length}>
        {displayedBlockTypes.includes(ServiceBlockTypes.MailService) && (
          <ServiceItem icon={IconSpinner} to="/services/courrier-entreprise">
            Service tournée
          </ServiceItem>
        )}
        {displayedBlockTypes.includes(ServiceBlockTypes.PackageDelivery) && (
          <ServiceItem icon={IconPackage} to="/services/livraison-colis">
            Course ponctuelle
          </ServiceItem>
        )}
        {displayedBlockTypes.includes(ServiceBlockTypes.UrgentTransport) && (
          <ServiceItem icon={IconEnvelope} to="/services/transport-urgent">
            Livraison urgente
          </ServiceItem>
        )}
      </ServicesList>
    </Layout>
  );
};

export default ServicesBlock;
