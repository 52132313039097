import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconEnvelope: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1.25 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M118.1 17.6c-.1-1-1-1.7-2-1.7H23.3c-.6 0-1.1.2-1.5.6-.4.4-.6 1-.5 1.5 0 .2 1.5 17.1 1.5 28.7 0 11.5-1.5 28.5-1.5 28.7 0 .6.1 1.1.5 1.5.4.4.9.6 1.5.6h92.8c1 0 1.8-.7 2-1.7 0-.1 1.7-12.6 1.7-29.1.1-16.4-1.6-29-1.7-29.1zM69.8 49.7L29.4 19.9h80.7L69.8 49.7zm-13.4-4.9L25.7 71c.5-6 1.1-16.4 1.1-24.2 0-8.1-.7-18.8-1.2-24.7l30.8 22.7zm3.3 2.5l10 7.4 10-7.4 31 26.4H28.8l30.9-26.4zm23.4-2.5l31.5-23.3c.5 4.8 1.3 14.1 1.3 25.3 0 10.9-.8 20.1-1.3 24.9L83.1 44.8zM-9.5 41.8h20.3c1.1 0 2-.9 2-2s-.9-2-2-2H-9.5c-1.1 0-2 .9-2 2 0 1.2.9 2 2 2zM11.1 47.8h-27.5c-1.1 0-2 .9-2 2s.9 2 2 2h27.5c1.1 0 2-.9 2-2s-.9-2-2-2zM10.8 58.2h-21.6c-1.1 0-2 .9-2 2s.9 2 2 2h21.6c1.1 0 2-.9 2-2 0-1.2-.9-2-2-2z" />
    </svg>
  );
};

export default IconEnvelope;
