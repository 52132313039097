import { Link } from 'gatsby';

import styled from '../styled';

import { ButtonProps, buttonStyles, variantSizeStyles, ButtonVariant } from './Button';

const ButtonLink = styled(Link)<ButtonProps & { to: string }>`
  ${buttonStyles};
  ${variantSizeStyles};
  display: inline-flex;
  text-decoration: none;
`;

ButtonLink.defaultProps = {
  variant: ButtonVariant.PRIMARY,
};

export default ButtonLink;
