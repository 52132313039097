import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconArrowLeft: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale * scale)};
        height: ${baselineScale(1 * scale * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M32.4 57.6l-4.8-4.8H79c1.5 0 2.8-1.2 2.8-2.8s-1.2-2.8-2.8-2.8H27.6l4.8-4.8c1.1-1.1 1.1-2.8 0-3.9s-2.8-1.1-3.9 0L17.1 50l11.4 11.4c1.1 1.1 2.8 1.1 3.9 0s1.1-2.8 0-3.8z" />
      <path d="M97.8 50C97.8 23.7 76.3 2.3 50 2.3 23.7 2.3 2.3 23.7 2.3 50c0 26.3 21.4 47.8 47.8 47.8 26.2 0 47.7-21.5 47.7-47.8zm-90 0c0-23.3 19-42.3 42.3-42.3s42.3 19 42.3 42.3S73.3 92.3 50 92.3 7.8 73.3 7.8 50z" />
    </svg>
  );
};

export default IconArrowLeft;
