import React, { InputHTMLAttributes, LabelHTMLAttributes } from 'react';

import styled, { scale, BREAKPOINTS, baseline } from '../styled';

const Box = styled.div`
  margin-bottom: ${scale(1.425)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-bottom: ${scale(1.375)};
  }
`;

const Label = styled.label<LabelHTMLAttributes<HTMLLabelElement>>`
  display: block;
  ${baseline(14, 1, 'm')};
  font-weight: bold;
  padding-left: ${scale(1)};
  margin-bottom: ${scale(0.25)};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }
`;

const Field = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${({ theme }): string => theme.color.gray.l};
  border-radius: ${scale(1)};
  background-color: #fff;

  ${baseline(14, 1, 'm')};
  padding: ${scale(0.625)} ${scale(1)};

  transition: border-color ${({ theme }): string => theme.timing.s};
  will-change: border-color;

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }

  &::-webkit-input-placeholder {
    color: ${({ theme }): string => theme.color.gray.m};
  }

  &::-moz-placeholder {
    color: ${({ theme }): string => theme.color.gray.m};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }): string => theme.color.gray.m};
  }

  &:hover {
    border-color: #000;
  }

  &[aria-invalid='true'] {
    border-color: ${({ theme }): string => theme.color.red.m};
  }
`;

const Input: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ children, ...props }) => {
  return (
    <Box>
      <Label htmlFor={props.id}>{children}</Label>
      <Field {...props} type="text" />
    </Box>
  );
};

export default Input;
