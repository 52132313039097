/* istanbul ignore next */
function onTestPassive() {
  return;
}

export function supportsPassiveEvents(): boolean {
  /* istanbul ignore next */
  if (typeof window === 'undefined') return false;

  let hasFeature = false;

  try {
    const options = Object.defineProperty({}, 'passive', {
      get: function () {
        hasFeature = true;
      },
    });
    window.addEventListener('testPassive', onTestPassive, options);
    window.removeEventListener('testPassive', onTestPassive, options);
  } catch (e) {}

  return hasFeature;
}
