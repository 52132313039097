import React from 'react';

import styled, { scale, useTheme, BREAKPOINTS } from '../styled';
import { loadGoogleMapsLibrary } from '../googleMaps';

const Layout = styled.div`
  position: relative;
  height: ${scale(18)};
`;

export const OFFICE_GEO = {
  LAT: 47.215977,
  LNG: -1.537386,
};

const MARKER_SIZE = {
  WIDTH: 90,
  HEIGHT: 97,
};

const ContactMap: React.FC = ({}) => {
  const { color } = useTheme();
  const mapRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /* istanbul ignore next */
    function handleOnLoad(): void {
      if (!mapRef?.current) return;

      const officeLatLng = new google.maps.LatLng(OFFICE_GEO.LAT, OFFICE_GEO.LNG);

      const map = new window.google.maps.Map(mapRef.current, {
        center: window.matchMedia(`(min-width: ${BREAKPOINTS.m})`).matches
          ? new google.maps.LatLng(OFFICE_GEO.LAT, -1.4)
          : officeLatLng,
        backgroundColor: color.gray.s,
        disableDefaultUI: true,
        minZoom: 10,
        maxZoom: 16,
        zoom: 12,
      });

      const marker = new google.maps.Marker({
        position: officeLatLng,
        icon: {
          size: new google.maps.Size(MARKER_SIZE.WIDTH, MARKER_SIZE.HEIGHT),
          anchor: new google.maps.Point(MARKER_SIZE.WIDTH / 2, MARKER_SIZE.HEIGHT),
          url: `data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 594.92 643.17" width="${MARKER_SIZE.WIDTH}" height="${MARKER_SIZE.HEIGHT}"><g style="isolation:isolate"><polygon points="215.63 501.4 297.48 643.17 379.33 501.4 215.63 501.4" fill="%23fff" fill-rule="evenodd"/><circle cx="297.46" cy="297.46" r="297.46" fill="%23fff"/><path d="M501.31,215.2s32.39,35.82,72.83,0C574.16,215.2,541.38,169.69,501.31,215.2Z" transform="translate(-143.91 0)" fill="%23c1ce30"/><path d="M343.28,316.2a68,68,0,1,1-68-68,68,68,0,0,1,68,68Z" transform="translate(-143.91 0)" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><circle cx="612.51" cy="316.22" r="68.01" transform="translate(-188.11 525.73) rotate(-45)" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><rect x="204.52" y="254.35" width="190.12" height="120.06" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><polyline points="204.52 254.34 299.59 325.79 394.65 254.34" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><line x1="204.52" y1="374.41" x2="279.01" y2="310.33" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><line x1="394.65" y1="374.41" x2="320.17" y2="310.33" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><path d="M269.86,322.46,382.52,196.81s7.71-10.19,21-10.19h21.6" transform="translate(-143.91 0)" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><polyline points="394.65 234.46 394.65 257.54 473.69 321.43" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><path d="M501.31,215.2s11.46,16.31,37.24,16.31,35.59-16.31,35.59-16.31-5.89-18.8-36.42-18.8C507.87,196.4,501.31,215.2,501.31,215.2Z" transform="translate(-143.91 0)" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/><line x1="380.04" y1="213.96" x2="448.18" y2="213.96" fill="none" stroke="%23000" stroke-miterlimit="8" stroke-width="8"/></g></svg>`,
        },
        map: map,
      });

      marker.addListener('click', function () {
        window.open('https://goo.gl/maps/2gcj2wYPDsPYi1yn9', '_blank');
      });

      new google.maps.Rectangle({
        strokeColor: '#000',
        strokeOpacity: 0.25,
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 0.25,
        map: map,
        bounds: { north: 90, south: -90, west: -180, east: 180 },
      });
    }

    /* istanbul ignore next */
    if (typeof window.google === 'undefined') {
      loadGoogleMapsLibrary(handleOnLoad);
    } else {
      handleOnLoad();
    }
  }, [color.gray.s]);

  return <Layout ref={mapRef} />;
};

export default ContactMap;
