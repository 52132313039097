import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconQuotation: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M91.2 25.3c-2.8-4.5-7.1-7.5-12.3-8.4-5.4-1-11.1.4-15.5 3.8C59 24 56.2 29 55.7 34.4c-.5 5.4 1.1 10.6 4.6 14.7 2.9 3.4 6.7 5.7 10.9 6.6l-1.6 25.2.2.5c.4 1.1 1.4 1.8 2.5 1.9h.2c1.1 0 2.1-.6 2.5-1.5.9-1.8 2-3.8 3.2-6.1 5.4-10.1 12.7-24 15.1-31.8 1.9-6.4 1.2-13.3-2.1-18.6zm-3.1 16.9c-1.9 6.2-7.3 16.9-12.1 26l1-17.4-2.7-.2c-3.8-.3-7.3-2.1-9.8-5-2.5-2.9-3.7-6.7-3.3-10.6.4-3.9 2.3-7.4 5.5-9.8 2.6-2 5.7-3 8.8-3 .8 0 1.6.1 2.4.2 4.6.8 7.2 3.6 8.6 5.8 2.5 3.9 3 9.2 1.6 14zM28.9 17c-5.4-1-11.1.4-15.5 3.8s-7.2 8.3-7.7 13.7c-.5 5.4 1.1 10.6 4.6 14.7 2.9 3.4 6.7 5.7 10.9 6.6L19.6 81l.2.5c.4 1.1 1.4 1.8 2.5 1.9h.2c1.1 0 2-.6 2.5-1.5.9-1.8 2-3.8 3.2-6.1 5.4-10.1 12.7-24 15.1-31.8 1.9-6.3 1.1-13.2-2.1-18.5-2.7-4.7-7.1-7.6-12.3-8.5zm9.2 25.2c-1.9 6.2-7.3 16.9-12.1 26l1-17.4-2.7-.2c-3.8-.3-7.3-2.1-9.8-5-2.5-2.9-3.7-6.7-3.3-10.6.4-3.9 2.3-7.4 5.5-9.8 2.6-2 5.7-3 8.8-3 .8 0 1.6.1 2.4.2 4.6.8 7.2 3.6 8.6 5.8 2.5 3.9 3 9.2 1.6 14z" />
    </svg>
  );
};

export default IconQuotation;
