import React from 'react';
import { SerializedStyles } from '@emotion/react';

import styled, { scale, BREAKPOINTS, hachureStyles } from '../styled';

import Carousel from './Carousel';
import CenteredBox from './CenteredBox';
import { IconBicycle, IconLock, IconTrees } from './icons';

const Layout = styled.section`
  position: relative;
  padding-top: ${scale(1)};
  padding-bottom: ${scale(1)};

  &:before,
  &:after {
    content: '';
    ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.l)};
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: ${scale(0.5)};
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
`;

const Slide = styled.div`
  position: relative;
  margin: ${scale(1)} ${scale(2)};
  width: 100%;

  text-align: center;
`;

const Icon = styled.svg<{ as?: React.ElementType }>`
  display: block;
  margin-top: ${scale(0.5)};
  margin-bottom: ${scale(1)};
  margin-left: auto;
  margin-right: auto;
`;

const Heading = styled.h3`
  margin-top: 0;
  margin-bottom: 0;

  text-transform: uppercase;
`;

const ReassuranceItem: React.FC<{ icon: React.ElementType }> = ({ children, icon, ...props }) => {
  return (
    <Slide {...props}>
      <Icon as={icon} scale={2.5} />
      <Heading>{children}</Heading>
    </Slide>
  );
};

const ReassuranceBlock: React.FC<{ id: string }> = ({ id, ...props }) => {
  return (
    <Layout {...props}>
      <CenteredBox>
        <Carousel
          id={id}
          configs={[
            { breakpoint: BREAKPOINTS.xs, items: 2 },
            { breakpoint: BREAKPOINTS.s, items: 3 },
          ]}
        >
          <ReassuranceItem icon={IconTrees}>Livraison écologique</ReassuranceItem>
          <ReassuranceItem icon={IconBicycle}>Réactivité & rapidité</ReassuranceItem>
          <ReassuranceItem icon={IconLock}>Lieu de stockage sécurisé</ReassuranceItem>
        </Carousel>
      </CenteredBox>
    </Layout>
  );
};

export default ReassuranceBlock;
