import React from 'react';
import { css } from '@emotion/react';

import { useTheme, scale as baselineScale } from '../../styled';

const IconPhoneHandset: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <svg
      {...props}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <g>
        <path
          fill={theme.color.green.s}
          d="M20.4 85.8S5.3 71.4 5.3 56s15.3-30.5 15.3-30.5S35 38.6 35 55.6 20.4 85.8 20.4 85.8zM75.3 81.8S55.1 63 55.1 42.8s20.5-40 20.5-40S94.8 20 94.8 42.3 75.3 81.8 75.3 81.8z"
        />
      </g>
      <path d="M36.9 55.7c0-17.7-14.4-31-15-31.6-.8-.7-2-.6-2.8.1-.6.6-15.8 15.8-15.8 31.9 0 14.4 12.2 27.6 15.1 30.5v11.1c0 1.1.9 2 2 2s2-.9 2-2V86.6c3-3 14.5-15.2 14.5-30.9zM22.4 67.5l6.9-7.6c.8-.8.7-2.1-.1-2.8-.8-.8-2.1-.7-2.8.1l-4 4.4v-15c0-1.1-.9-2-2-2s-2 .9-2 2v9.5L14 51.7c-.7-.8-2-.8-2.8 0-.8.7-.8 2 0 2.8l7.2 7.2v19C14.1 75.6 7.3 66 7.3 56.1c0-11.8 9.7-23.6 13.3-27.6 3.5 3.7 12.3 14.3 12.3 27.2 0 10.9-6.4 20.2-10.5 25.1V67.5zM96.8 42.3c0-22.9-19.2-40.3-20-41-.8-.6-1.9-.6-2.7.1C73.2 2.2 53 22 53 42.8c0 19.1 16.8 36.6 20.3 39.9v15c0 1.1.9 2 2 2s2-.9 2-2v-15c3.6-3.5 19.5-19.7 19.5-40.4zM77.3 64.9l9.8-10.5c.7-.8.7-2-.1-2.8-.8-.7-2-.7-2.8.1l-6.9 7.2V47.5L87.1 37c.7-.8.7-2-.1-2.8-.8-.7-2-.7-2.8.1l-6.9 7.2V27.6c0-1.1-.9-2-2-2s-2 .9-2 2v19.5l-4.9-5.5c-.7-.9-2-.9-2.8-.2-.9.7-.9 2-.2 2.8l7.9 8.9v23.6c-5.6-6.2-16.2-19.8-16.2-34 0-16.1 14-32.3 18.5-37.1 4.3 4.4 17.2 19 17.3 36.6 0 15.6-10.2 28.8-15.6 34.7v-12z" />
    </svg>
  );
};

export default IconPhoneHandset;
