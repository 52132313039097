import React from 'react';
import { Link } from 'gatsby';
import isPropValid from '@emotion/is-prop-valid';
import { SerializedStyles } from '@emotion/react';

import {
  IconChevronRight,
  IconSpinner,
  IconPackage,
  IconEnvelope,
  IconBulkyDelivery,
} from '../../../icons';
import styled, { baseline, scale, BREAKPOINTS } from '../../../../styled';

import { activeLinkStyles } from '.';

const ToggleButton = styled.button<{
  isActive: boolean;
  headerIsSticky: boolean;
  isHomePage: boolean;
}>`
  /* button reset */
  width: 100%;
  border: none;
  border-radius: 0;
  overflow: visible;
  padding-top: ${scale(0.5)};
  padding-bottom: ${scale(0.5)};
  box-sizing: border-box;

  color: #000;
  ${baseline(20, 1, 'm')};
  background-color: transparent;
  text-transform: none;

  transition: color ${({ theme }): string => theme.timing.s},
    background-color ${({ theme }): string => theme.timing.s};
  will-change: color, background-color;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding: ${scale(1.5)} 0;
    color: ${({ headerIsSticky, isHomePage }): string =>
      headerIsSticky ? '#000' : isHomePage ? '#fff' : '#000'};

    ${baseline(16, 1, 'l')};
  }

  &:hover {
    color: #fff;
    background-color: ${({ theme }): string => theme.color.green.m};

    @media (min-width: ${BREAKPOINTS.m}) {
      color: ${({ theme }): string => theme.color.green.m};
      background-color: transparent;
    }
  }

  &:before {
    display: none;

    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      position: absolute;
      display: block;
      top: auto;
      left: 0;
      right: 0;
      bottom: ${scale(1.5)};
      width: 100%;
      height: 1px;
      background-color: #000;

      opacity: 0;
      transition: bottom 0.2s, opacity 0.16s, background-color 0.16s;
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      will-change: bottom, background-color, opacity;
    }
  }

  &:after {
    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${scale(1.25)};
      width: 100%;
      height: 1px;
      background-color: #000;

      opacity: 0;
      transition: bottom 0.2s, opacity 0.16s, background-color 0.16s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      will-change: background-color, opacity;
    }
  }

  &[aria-current],
  &:hover {
    @media (min-width: ${BREAKPOINTS.m}) {
      ${({ theme, headerIsSticky, isHomePage }): SerializedStyles =>
        activeLinkStyles({ theme, headerIsSticky, isHomePage })};
    }
  }
`;

const ToggleButtonIcon = styled(IconChevronRight, { shouldForwardProp: isPropValid })<{
  isActive: boolean;
}>`
  position: absolute;
  top: 50%;
  right: ${scale(1)};

  transform: translateY(-50%)
    rotate(
      ${({ isActive }): string => {
        /* istanbul ignore next */
        return isActive ? '-90deg' : '90deg';
      }}
    );
  transition: transform ${({ theme }): string => theme.timing.s};
  will-change: transform;

  @media (min-width: ${BREAKPOINTS.m}) {
    display: none;
  }
`;

const List = styled.ul<{ isVisible: boolean }>`
  overflow: hidden;
  margin-bottom: 0;
  padding-left: 0;

  height: ${({ isVisible }): string => {
    /* istanbul ignore next */
    return isVisible ? scale(10) : '0';
  }};
  opacity: ${({ isVisible }): string => {
    /* istanbul ignore next */
    return isVisible ? '1' : '0';
  }};

  transition: height ${({ theme }): string => theme.timing.m},
    opacity ${({ theme }): string => theme.timing.m};
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: height, opacity;

  @media (min-width: ${BREAKPOINTS.m}) {
    position: absolute;
    z-index: 1;
    top: ${scale(6)};
    right: 0;
    left: 0;
    height: 0;
    width: 100%;
    max-width: calc(var(--lineHeight) * 45 * 1rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    opacity: 1;
    background-color: #fff;
    box-shadow: 0px 0px 0px 1px transparent;

    transition: top 0.8s, height ${({ theme }): string => theme.timing.m},
      box-shadow ${({ theme }): string => theme.timing.m};
    will-change: height, top, box-shadow;
  }

  [data-test-is-sticky='true'] & {
    @media (min-width: ${BREAKPOINTS.m}) {
      top: ${scale(3.5)};
    }
  }
`;

const Item = styled.li`
  margin-top: ${scale(0.5)};
  margin-bottom: ${scale(0.5)};

  @media (min-width: ${BREAKPOINTS.m}) {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    border-right: 1px solid ${({ theme }): string => theme.color.gray.s};

    &:last-child {
      border-right: none;
    }
  }

  &:before {
    display: none;
  }
`;

const ItemLink = styled.a<{ as: React.ElementType; to: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${scale(0.5)};
  padding-bottom: ${scale(0.5)};

  color: #000;
  ${baseline(16, 1, 'm')};
  background-color: transparent;
  text-decoration: none;

  transition: color ${({ theme }): string => theme.timing.s},
    background-color ${({ theme }): string => theme.timing.s};
  will-change: color, background-color;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: ${scale(1)};
    padding-bottom: ${scale(1)};

    ${baseline(16, 1, 'l')};
  }

  &:hover {
    color: #fff;
    background-color: ${({ theme }): string => theme.color.green.m};
  }
`;

const ItemLinkIcon = styled.svg<{ as?: React.ElementType }>`
  margin-right: ${scale(1)};
`;

const ServicesLinks: React.FC<{ headerIsSticky: boolean; isHomePage: boolean }> = ({
  headerIsSticky,
  isHomePage,
}) => {
  const [isVisible, setVisibility] = React.useState(false);

  const handleClick = React.useCallback(
    /* istanbul ignore next */
    (value: boolean) => (): void => {
      setVisibility(value);
    },
    [setVisibility]
  );

  return (
    <React.Fragment>
      <ToggleButton
        type="button"
        isActive={isVisible}
        onClick={handleClick(!isVisible)}
        isHomePage={isHomePage}
        headerIsSticky={headerIsSticky}
      >
        Services
        <ToggleButtonIcon isActive={isVisible} />
      </ToggleButton>
      <List isVisible={isVisible}>
        <Item>
          <ItemLink as={Link} to="/services/courrier-entreprise">
            <ItemLinkIcon as={IconSpinner} />
            Service tournée
          </ItemLink>
        </Item>
        <Item>
          <ItemLink as={Link} to="/services/livraison-colis">
            <ItemLinkIcon as={IconPackage} />
            Course ponctuelle
          </ItemLink>
        </Item>
        <Item>
          <ItemLink as={Link} to="/services/transport-urgent">
            <ItemLinkIcon as={IconEnvelope} />
            Livraison urgente
          </ItemLink>
        </Item>
        <Item>
          <ItemLink as={Link} to="/services/livraison-volumineuse">
            <ItemLinkIcon as={IconBulkyDelivery} />
            Livraison volumineuse
          </ItemLink>
        </Item>
      </List>
    </React.Fragment>
  );
};

export default ServicesLinks;
