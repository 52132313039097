import React from 'react';
import { css } from '@emotion/react';

import { useTheme, scale as baselineScale } from '../../styled';

const IconLock: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path
        fill={theme.color.green.s}
        d="M63.3 94H36.7c-12.1 0-21.8-9.8-21.8-21.8V54.3c0-12.1 9.8-21.8 21.8-21.8h26.7c12.1 0 21.8 9.8 21.8 21.8v17.9c0 12-9.8 21.8-21.9 21.8z"
      />
      <path d="M71.1 31.8c.1-2.3.3-16.7-7.4-24.9-3.6-3.8-8.2-5.7-13.9-5.7-5.8 0-10.5 2-14 5.8-7.3 7.9-7.1 21.4-6.9 24.8-9.3 3.2-16 12.1-16 22.5v17.9C12.9 85.3 23.6 96 36.7 96h26.6c13.2 0 23.9-10.7 23.9-23.8V54.3c0-10.4-6.8-19.3-16.1-22.5zM38.7 9.7c2.8-3 6.4-4.5 11.1-4.5 4.5 0 8.2 1.5 11 4.4 6 6.3 6.4 17.8 6.3 21.2-1.2-.2-2.5-.3-3.7-.3l-1.9-.1H32.8c0-4.2.4-14.7 5.9-20.7zm44.5 62.4c0 10.9-8.9 19.8-19.8 19.8H36.7c-10.9 0-19.8-8.9-19.8-19.8V54.2c0-10.9 8.9-19.7 19.8-19.7h26.7c10.9 0 19.8 8.9 19.8 19.8v17.8z" />
      <path d="M56 63.2c0-3.3-2.7-6-6-6s-6 2.7-6 6c0 2.2 1.2 4.2 3 5.2.1 3.3.5 6 3 6 2.1 0 3-2.7 3.1-6.1 1.7-1 2.9-2.9 2.9-5.1z" />
    </svg>
  );
};

export default IconLock;
