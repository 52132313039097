import React from 'react';

import styled, { scale, BREAKPOINTS } from '../styled';

import Mask from './Mask';

const Layout = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Slides = styled.div`
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
`;

interface ConfigItem {
  breakpoint: BREAKPOINTS;
  items: number;
}

const Slide = styled.div<{ config: ConfigItem }>`
  display: flex;
  flex-shrink: 0;
  width: ${({ config }): number => 100 / config.items}%;

  &:nth-of-type(${({ config }): number => config.items}n + 1) {
    scroll-snap-align: start;
  }
`;

const Pagination = styled.nav`
  display: flex;
  justify-content: center;

  margin-bottom: ${scale(1)};
`;

const Page = styled.a<{ isActive: boolean }>`
  display: block;
  margin: ${scale(0.125)};
  width: ${scale(0.75)};
  height: ${scale(0.75)};
  box-sizing: border-box;

  border: 1px solid ${({ theme }): string => theme.color.gray.l};
  border-radius: 50%;
  background-color: #fff;
  box-shadow: ${({ isActive, theme }): string => {
    /* istanbul ignore next */
    return isActive
      ? `inset 0 0 ${scale(1.5)} ${theme.color.green.m}`
      : `inset 0 0 0 ${theme.color.green.m}`;
  }};

  text-decoration: none;
  transition: box-shadow ${({ theme }): string => theme.timing.m};
  will-change: box-shadow;

  &:hover {
    border-color: #000;
  }
`;

enum NavigationButtonDirection {
  Left = 'left',
  Right = 'right',
}

const NavigationButton = styled.a<{ direction: NavigationButtonDirection }>`
  display: flex;
  position: absolute;
  top: 50%;
  padding: ${scale(0.25)};
  transform: ${
    /* istanbul ignore next */ ({ direction }): string =>
      direction === NavigationButtonDirection.Left
        ? 'translate(-50%, -50%)'
        : 'translate(50%, -50%) rotate(-180deg)'
  };
  left: ${
    /* istanbul ignore next */ ({ direction }): string =>
      direction === NavigationButtonDirection.Left ? '0' : 'auto'
  };
  right: ${
    /* istanbul ignore next */ ({ direction }): string =>
      direction === NavigationButtonDirection.Right ? '0' : 'auto'
  };
  z-index: 1;
`;

const SVG = styled.svg`
  width: ${scale(1.5)};
  height: ${scale(1.5)};

  border-radius: 50%;
  background-color: #fff;
  fill: ${/* istanbul ignore next */ ({ theme }): string => theme.color.green.m};

  transition: fill ${/* istanbul ignore next */ ({ theme }): string => theme.timing.m},
    background-color ${/* istanbul ignore next */ ({ theme }): string => theme.timing.m};
  will-change: fill, background-color;
  cursor: pointer;

  &:hover {
    fill: #000;
    background-color: ${/* istanbul ignore next */ ({ theme }): string => theme.color.green.s};
    background-image: repeating-linear-gradient(
      -45deg,
      transparent,
      transparent 2px,
      rgb(0, 0, 0, 0.06) 2px,
      rgb(0, 0, 0, 0.06) 4px
    );
  }
`;

/* istanbul ignore next */
const IconCircle: React.FC = (props) => {
  return (
    <SVG {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.5 95.5">
      <path d="M30.1 55.3l-4.8-4.8h51.4c1.5 0 2.8-1.2 2.8-2.8s-1.2-2.8-2.8-2.8H25.3l4.8-4.8c1.1-1.1 1.1-2.8 0-3.9s-2.8-1.1-3.9 0L14.8 47.7l11.4 11.4c1.1 1.1 2.8 1.1 3.9 0s1.1-2.8 0-3.8z" />
      <path d="M95.5 47.7C95.5 21.4 74 0 47.7 0S0 21.4 0 47.7s21.4 47.8 47.8 47.8C74 95.5 95.5 74 95.5 47.7zm-90 0c0-23.3 19-42.3 42.3-42.3s42.3 19 42.3 42.3S71 90 47.7 90 5.5 71 5.5 47.7z" />
    </SVG>
  );
};

function getConfig(configs: ConfigItem[]): ConfigItem {
  const defaultValue = {
    breakpoint: BREAKPOINTS.s,
    items: 1,
  };

  /* istanbul ignore next */
  if (typeof window === 'undefined') return defaultValue;

  return configs.reduce(function (accumulator, currentValue) {
    /* istanbul ignore next */
    return window.matchMedia(`(min-width: ${currentValue.breakpoint})`).matches
      ? currentValue
      : accumulator;
  }, defaultValue);
}

const Carousel: React.FC<{
  id: string;
  hasPagination?: boolean;
  hasNavigation?: boolean;
  configs?: ConfigItem[];
}> = ({ id, configs = [], hasNavigation = false, hasPagination = true, children, ...props }) => {
  const [width, setWidth] = React.useState(0);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const slidesRef = React.useRef<HTMLDivElement>(null);
  const config = getConfig(configs);

  const pageCount = Math.ceil(React.Children.count(children) / config.items);
  const currentPageIndex = Math.ceil(scrollPosition / width) + 1;
  const previousPageAnchor = `#${id}-${currentPageIndex - 1}`;
  const nextPageAnchor = `#${id}-${currentPageIndex + 1}`;

  const handleScroll = React.useCallback(
    /* istanbul ignore next */
    function handleScroll(event) {
      setScrollPosition(event.target.scrollLeft);
    },
    [setScrollPosition]
  );

  const getWidth = React.useCallback(
    /* istanbul ignore next */
    function getWidth() {
      if (!slidesRef || !slidesRef.current) return 0;

      return slidesRef.current.clientWidth;
    },
    [slidesRef]
  );

  const handleResize = React.useCallback(
    /* istanbul ignore next */
    function handleScroll() {
      setWidth(getWidth());
    },
    [setWidth, getWidth]
  );

  React.useEffect(
    /* istanbul ignore next */
    function setupWidth() {
      if (typeof window === 'undefined') return;

      setWidth(getWidth());
      window.addEventListener('resize', handleResize);
      return function removeSetupWidth(): void {
        window.removeEventListener('resize', handleResize);
      };
    },
    [setWidth, getWidth, handleResize]
  );

  return (
    <Layout {...props}>
      {
        /* istanbul ignore next */ hasNavigation && currentPageIndex !== 1 && (
          <NavigationButton href={previousPageAnchor} direction={NavigationButtonDirection.Left}>
            <Mask>{`Aller à la slide ${currentPageIndex - 1}`}</Mask>
            <IconCircle />
          </NavigationButton>
        )
      }
      <Slides ref={slidesRef} onScroll={handleScroll}>
        {React.Children.map(children, (child, index) => {
          return (
            <Slide id={`${id}-${index + 1}`} config={config}>
              {child}
            </Slide>
          );
        })}
      </Slides>
      {
        /* istanbul ignore next */ hasNavigation && currentPageIndex !== pageCount && (
          <NavigationButton href={nextPageAnchor} direction={NavigationButtonDirection.Right}>
            <Mask>{`Aller à la slide ${currentPageIndex + 1}`}</Mask>
            <IconCircle />
          </NavigationButton>
        )
      }
      {hasPagination && pageCount > 1 && (
        <Pagination>
          {[...Array(pageCount).keys()].map((_, index) => {
            const anchor = `#${id}-${index * config.items + 1}`;
            const isActive = Math.ceil(scrollPosition / width) === index;

            return (
              <Page href={anchor} isActive={isActive} key={anchor}>
                <Mask>{`Aller à la slide ${index + 1}`}</Mask>
              </Page>
            );
          })}
        </Pagination>
      )}
    </Layout>
  );
};

export default Carousel;
