import styled from '../styled';

const Mask = styled.span<{ as?: React.ElementType }>`
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
`;

export default Mask;
