import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconFacebook: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M57.1 98.2H43.8c-3.2 0-5.9-2.7-5.9-5.9V56.4h-11c-3.2 0-5.9-2.7-5.9-5.9V40.2c0-3.2 2.7-5.9 5.9-5.9h10.9V25c0-13.8 9.1-23.1 22.7-23.1h10.1c3.2 0 5.9 2.7 5.9 5.9V20c0 3.2-2.7 5.9-5.9 5.9h-7.5v8.4h10c1.8 0 3.5.8 4.6 2.2 1.1 1.4 1.6 3.2 1.2 4.9l-2.1 10.2c-.5 2.7-3 4.7-5.8 4.7h-8v36c0 3.1-2.7 5.9-5.9 5.9zM26.9 39.8c-.2 0-.4.2-.4.4v10.4c0 .2.2.4.4.4h16.5v41.3c0 .2.2.4.4.4h13.3c.2 0 .4-.2.4-.4V50.8H71c.2 0 .4-.1.4-.2l2.1-10.3c0-.2 0-.3-.1-.4-.1-.1-.2-.1-.4-.1H57.6V26.3c0-3.6 1-5.9 6-5.9h7c.2 0 .4-.2.4-.4V7.7c0-.2-.2-.4-.4-.4H60.5c-10.4 0-17.2 6.9-17.2 17.6v14.8H26.9z" />
    </svg>
  );
};

export default IconFacebook;
