import React from 'react';
import { SerializedStyles } from '@emotion/react';

import styled, { BREAKPOINTS, scale, hachureStyles, baseline } from '../styled';

import CenteredBox, { CenteredBoxSizes } from './CenteredBox';
import ButtonLink from './ButtonLink';
import { ButtonVariant } from './Button';

const Background = styled.section`
  ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.s)};
  background-color: ${({ theme }): string => theme.color.green.m};
`;

const Layout = styled(CenteredBox)<{ size: CenteredBoxSizes }>`
  padding-top: ${scale(2)};
  padding-bottom: ${scale(2)};

  color: #fff;
  text-align: center;

  @media (min-width: ${BREAKPOINTS.s}) {
    padding-top: ${scale(4)};
    padding-bottom: ${scale(4)};
  }
`;

const Heading = styled.h2`
  ${baseline(20, 1, 'm')};
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.s}) {
    padding-left: ${scale(2)};
    padding-right: ${scale(2)};

    ${baseline(25, 1, 'm')};
  }

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(40, 2, 'l')};
  }
`;

const Content = styled.div`
  margin-bottom: ${scale(1)};

  @media (min-width: ${BREAKPOINTS.s}) {
    margin-bottom: ${scale(2)};
  }
`;

const Actions = styled.p`
  margin-bottom: 0;
`;

const RequestQuoteBlock: React.FC<JSX.IntrinsicElements['div']> = (props) => {
  return (
    <Background {...props}>
      <Layout size={CenteredBoxSizes.S}>
        <Heading>Navette entre vos différents établissements ou partenaires</Heading>
        <Content>
          <p>
            Votre entreprise détient plusieurs établissements à des adresses distinctes dans Nantes
            et son agglomération ? Nous mettons en place{' '}
            <strong>une tournée dédiée pour faciliter vos échanges !</strong>
          </p>
          <p>
            Vous échangez de façon quotidienne des courriers ou colis entre plusieurs établissements
            d’un même groupe, ou bien échangez de manière récurrente des documents et marchandises
            auprès du même interlocuteur ?
          </p>
          <p>
            Green Course vous apporte la solution la plus adaptée grâce à ce service spécialement
            étudié.
          </p>
        </Content>
        <Actions>
          <ButtonLink to="/contact" variant={ButtonVariant.OUTLINED}>
            Demander un devis
          </ButtonLink>
        </Actions>
      </Layout>
    </Background>
  );
};

export default RequestQuoteBlock;
