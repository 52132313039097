import React, { InputHTMLAttributes, LabelHTMLAttributes } from 'react';

import styled, { scale, BREAKPOINTS, baseline } from '../styled';

const Box = styled.div`
  margin-bottom: ${scale(1)};
`;

const Label = styled.label<LabelHTMLAttributes<HTMLLabelElement>>`
  position: relative;
  display: block;
  ${baseline(14, 1, 'm')};

  margin-left: ${scale(2)};
  cursor: pointer;

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(16, 1, 'l')};
  }

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    display: block;
    width: ${scale(1)};
    height: ${scale(1)};
    background-color: #fff;
    top: 50%;
    left: ${scale(-2)};
    transform: translateY(-50%);
    border: 1px solid ${({ theme }): string => theme.color.gray.l};
    border-radius: ${scale(0.125)};
    transition: border-color ${({ theme }): string => theme.timing.s};
    will-change: border-color;
  }

  &:hover {
    &:before {
      border-color: #000;
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: ${scale(0.6125)};
    height: ${scale(0.5)};
    top: 50%;
    left: ${scale(-1.8125)};
    transform: translateY(-50%);
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.05 36.14" fill="${({
      theme,
    }): string =>
      theme.color.green.m.replace(
        '#',
        '%23'
      )}"><path d="M69.8,32.7,40.7,61.8,30.2,51.4a2.76,2.76,0,1,0-3.9,3.9L38,67a3.86,3.86,0,0,0,2.7,1.1A4,4,0,0,0,43.4,67L73.7,36.7a2.72,2.72,0,0,0,0-3.9A2.81,2.81,0,0,0,69.8,32.7Z" transform="translate(-25.48 -31.96)"/></svg>');
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity ${({ theme }): string => theme.timing.s};
    will-change: 0;
  }
`;

const Field = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  display: none;

  &:checked {
    + label {
      &:before {
        border-color: ${({ theme }): string => theme.color.green.m};
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &[aria-invalid='true'] {
    + label {
      &:before {
        border-color: ${({ theme }): string => theme.color.red.m};
      }
    }
  }
`;

const Checkbox: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ children, ...props }) => {
  return (
    <Box>
      <Field {...props} type="checkbox" />
      <Label htmlFor={props.id} tabIndex={0}>
        {children}
      </Label>
    </Box>
  );
};

export default Checkbox;
