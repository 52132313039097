import React, { HTMLAttributes } from 'react';
import { css, SerializedStyles } from '@emotion/react';

import styled, { scale, BREAKPOINTS, baseline } from '../../../../styled';

const Button = styled.button`
  color: #000;

  position: relative;
  padding: ${scale(0.5)};
  border: none;
  border-radius: 0;
  background-color: transparent;

  color: #000;
  font-weight: bold;
  font-size:
  text-transform: uppercase;
  ${baseline(12, 1, 'm')};

  margin-left: ${scale(2)};
  cursor: pointer;


  transition: none;
  will-change: unset;
  cursor: pointer;
  overflow: visible;

  @media (min-width: ${BREAKPOINTS.m}) {
    display: none;
  }

  &:before {
    display: none;
  }

  &:hover {
    background-color: transparent;
    border-color: inherit;
    color: #000;

    &:before {
      right: 0;
      opacity: 1;
      background-size: auto;
    }
  }
`;

const Icon = styled.span`
  position: relative;
  display: block;
  margin: 0 auto;
  width: ${scale(1.5)};
  height: ${scale(1)};
`;

const Bars = styled.span<{ isActive: boolean }>`
  display: block;
  position: absolute;
  top: auto;
  bottom: 0;
  width: ${scale(1.5)};
  height: 3px;
  margin-top: -2px;

  background-color: #000;

  transition-delay: ${({ isActive }): string => {
    /* istanbul ignore next */
    return isActive ? '0.22s' : '0.13s';
  }};
  transition-duration: 0.13s;
  transition-timing-function: ${({ isActive }): string => {
    /* istanbul ignore next */
    return isActive
      ? 'cubic-bezier(0.215, 0.61, 0.355, 1)'
      : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)';
  }};
  transition-property: transform;

  ${({ isActive }): SerializedStyles => {
    /* istanbul ignore next */
    if (!isActive) return css``;

    /* istanbul ignore next */
    return css`
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    `;
  }}

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: ${({ isActive }): string => {
      /* istanbul ignore next */
      return isActive ? '0' : '-10px';
    }};
    width: ${scale(1.5)};
    height: 3px;

    background-color: #000;

    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    transition: ${({ isActive }): string => {
      /* istanbul ignore next */
      return isActive
        ? 'top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s'
        : 'top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19)';
    }};

    ${({ isActive }): SerializedStyles => {
      /* istanbul ignore next */
      if (!isActive) return css``;

      /* istanbul ignore next */
      return css`
        transform: rotate(-90deg);
      `;
    }}
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: ${({ isActive }): string => {
      /* istanbul ignore next */
      return isActive ? '0' : '-20px';
    }};
    bottom: -10px;
    width: ${scale(1.5)};
    height: 3px;

    background-color: #000;

    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    transition: ${({ isActive }): string => {
      /* istanbul ignore next */
      return isActive
        ? 'top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333)'
        : 'top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s';
    }};
  }
`;

const Menu: React.FC<HTMLAttributes<HTMLButtonElement> & { isActive: boolean }> = ({
  isActive,
  ...props
}) => {
  return (
    <Button {...props}>
      <Icon aria-hidden={true}>
        <Bars isActive={isActive} />
      </Icon>
      Menu
    </Button>
  );
};

export default Menu;
