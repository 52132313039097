import { css, SerializedStyles, Theme } from '@emotion/react';

import styled, { scale, BREAKPOINTS } from '../styled';

export enum ButtonVariant {
  PRIMARY = 'primary',
  OUTLINED = 'outlined',
}

export function variantSizeStyles({
  variant,
  theme,
}: {
  variant?: ButtonVariant;
  theme: Theme;
}): SerializedStyles {
  if (variant !== ButtonVariant.OUTLINED) return css``;

  return css`
    border-color: ${theme.color.green.m};
    color: ${theme.color.green.m};
    background-color: #fff;
  `;
}

export function buttonStyles({ theme }: { theme: Theme }): SerializedStyles {
  return css`
    position: relative;
    padding: calc(var(--fontSize) * var(--lineHeight) / 2 * 1px - 2px) ${scale(1.25)};
    border: 2px solid ${theme.color.green.m};
    box-shadow: none;
    border-radius: ${scale(1)};
    background-color: ${theme.color.green.m};
    line-height: inherit;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;

    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;


    transition: border-color ${theme.timing.m}, color ${theme.timing.m}, background-color ${
    theme.timing.m
  };
    will-change: border-color, color, background-color;
    cursor: pointer;

    @media (min-width: ${BREAKPOINTS.s}) {
      width: auto;
      box-sizing: content-box;
      justify-content: normal;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: -10%;
      bottom: 0;
      opacity: 0;

      border-radius: ${scale(1)};
      background-image: repeating-linear-gradient(-45deg, transparent, transparent 2px, rgb(0,0,0,0.06) 2px, rgb(0,0,0,0.06) 4px);
      background-size: 0%;
      background-repeat: no-repeat;
      background-position: center;

      transition: background-size ${theme.timing.s}, opacity ${theme.timing.m}, right ${
    theme.timing.m
  };
      will-change: background-size, opacity, right;
    }


    &:hover {
      background-color: ${theme.color.green.s};
      border-color: ${theme.color.green.s};
      color: #000;

      &:before {
        right: 0;
        opacity: 1;
        background-size: 100%;
      }
    }

    &[disabled] {
      background-color: ${theme.color.gray.m};
      border-color: ${theme.color.gray.m};
      color: #000;

      &:before {
        right: 0;
        opacity: 1;
        background-size: 100%;
      }
    }
  }
  `;
}

export interface ButtonProps {
  variant?: ButtonVariant;
}

const Button = styled.button<ButtonProps>`
  ${buttonStyles};
  display: inline-flex;
  flex: no-wrap;
  ${variantSizeStyles}

  *:not(last-child) {
    margin-right: ${scale(0.75)};
  }
`;

Button.defaultProps = {
  type: 'button',
  variant: ButtonVariant.PRIMARY,
};

export default Button;
