import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconSpinner: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M91 13.3c-1.1-.1-2.1.8-2.1 1.9l-.8 11.3C80 14.7 66.6 7.6 52.1 7.6c-24 0-43.5 19.2-43.5 42.8s19.5 42.8 43.5 42.8c16.2 0 31-8.8 38.5-23 .5-1 .1-2.2-.8-2.7-1-.5-2.2-.1-2.7.8-6.8 12.9-20.3 20.9-35 20.9-21.8 0-39.5-17.4-39.5-38.8s17.7-38.8 39.5-38.8c13.2 0 25.4 6.5 32.7 17.1L74.6 28c-1.1-.1-2.1.8-2.1 1.9-.1 1.1.8 2.1 1.9 2.1l15.2 1h.1c.5 0 .9-.2 1.3-.5s.6-.8.7-1.4l1.1-15.8c.1-1-.7-2-1.8-2z" />
    </svg>
  );
};

export default IconSpinner;
