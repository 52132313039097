import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';

import { SocialNetworkLinks } from '../../pages/contact';
import styled, { scale, hachureStyles, BREAKPOINTS, baseline, useTheme } from '../../styled';
import { IconFacebook, IconInstagram } from '../icons';
import CenteredBox from '../CenteredBox';
import SocialIconLink from '../SocialIconLink';
import ButtonLink from '../ButtonLink';
import { ButtonVariant } from '../Button';

const Layout = styled(CenteredBox)`
  padding-top: ${scale(3)};
  padding-bottom: ${scale(1)};
`;

const SocialLinks: React.FC = ({ children, ...props }) => {
  return (
    <nav>
      <ul
        {...props}
        css={css`
          display: flex;
          justify-content: center;
          padding-left: 0;
          margin-bottom: ${scale(1)};

          @media (min-width: ${BREAKPOINTS.s}) {
            margin-bottom: ${scale(2)};
          }
        `}
      >
        {React.Children.map(children, (child) => {
          return (
            <li
              css={css`
                display: inherit;

                @media (min-width: ${BREAKPOINTS.s}) {
                  padding-top: ${scale(0.25)};
                  padding-bottom: ${scale(0.25)};
                }

                &:before {
                  display: none;
                }

                @media (min-width: ${BREAKPOINTS.s}) {
                  > a > svg {
                    width: ${scale(1.5)};
                    height: ${scale(1.5)};
                  }
                }
              `}
            >
              {child}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const InternalLinks: React.FC = ({ children, ...props }) => {
  return (
    <nav>
      <ul
        {...props}
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-left: 0;
          margin-bottom: ${scale(2)};

          ${baseline(14, 1, 'm')};

          @media (min-width: ${BREAKPOINTS.s}) {
            flex-direction: row;
          }

          @media (min-width: ${BREAKPOINTS.m}) {
            ${baseline(14, 1, 'l')};
          }
        `}
      >
        {React.Children.map(children, (child) => {
          return (
            <li
              css={css`
                display: inherit;
                padding-top: ${scale(0.25)};
                padding-bottom: ${scale(0.25)};
                width: 100%;

                @media (min-width: ${BREAKPOINTS.s}) {
                  width: auto;
                }

                &:before {
                  display: none;
                }

                &:not(:last-child) {
                  margin-bottom: ${scale(0.5)};

                  @media (min-width: ${BREAKPOINTS.s}) {
                    margin-right: ${scale(1)};
                    margin-bottom: ${scale(0)};
                  }
                }

                > a {
                  padding: calc(var(--fontSize) * var(--lineHeight) / 4 * 1px - 2px) ${scale(1.25)};
                }
              `}
            >
              {child}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

const Footer: React.FC = () => {
  const theme = useTheme();

  return (
    <footer
      css={css`
        ${hachureStyles(theme.hachure.m)};
      `}
    >
      <Layout>
        <SocialLinks>
          <SocialIconLink
            href={SocialNetworkLinks.Facebook}
            data-testid="social-link-facebook"
            css={css`
              margin-left: ${scale(0.5)};
              margin-right: ${scale(0.5)};
            `}
            icon={<IconFacebook scale={1} />}
          >
            Facebook
          </SocialIconLink>
          <SocialIconLink
            href={SocialNetworkLinks.Instagram}
            data-testid="social-link-instagram"
            css={css`
              margin-left: ${scale(0.5)};
              margin-right: ${scale(0.5)};
            `}
            icon={<IconInstagram scale={1} />}
          >
            Instagram
          </SocialIconLink>
        </SocialLinks>
        <InternalLinks>
          <ButtonLink variant={ButtonVariant.OUTLINED} to="/green-philosophie">
            Green philosophy
          </ButtonLink>
          <ButtonLink variant={ButtonVariant.OUTLINED} to="/pimp-my-remorque">
            Pimp my remorque
          </ButtonLink>
          <ButtonLink to="/contact">Contactez-nous</ButtonLink>
        </InternalLinks>
        <p
          css={css`
            margin-bottom: 0;

            ${baseline(12, 1, 'm')};
            text-align: center;

            @media (min-width: ${BREAKPOINTS.m}) {
              ${baseline(12, 1, 'l')};
            }
          `}
        >
          Copyright © {new Date().getFullYear()} - Green Course Tous droits réservés -{' '}
          <Link to="/politique-confidentialite">Politique de confidentialité</Link> -{' '}
          <Link to="/mentions-legales">Mentions légales</Link> - Création par Business to Web
        </p>
      </Layout>
    </footer>
  );
};

export default Footer;
