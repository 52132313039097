import React from 'react';

import { useTrackingContext } from './tracking';

export function validateEmail(email: string): boolean {
  /* Regular expression from https://emailregex.com/ */
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailRegEx.test(email);
}

export function validatePhoneNumber(phoneNumber: string): boolean {
  /* Regular expression from https://stackoverflow.com/questions/38483885/regex-for-french-telephone-numbers */
  const phoneNumberRegEx = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

  return phoneNumberRegEx.test(phoneNumber);
}

export function urlEncode(data: { [key: string]: string }): string {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

export function getPagePathName() {
  return window.location.origin + window.location.pathname;
}

export function generateFormEventTrackingObject(
  eventType: 'form_start' | 'form_submit',
  formId: string,
  formName: string
) {
  return {
    event: eventType,
    eventModel: {
      form_id: formId,
      form_name: formName,
      form_destination: getPagePathName(),
    },
  };
}

export const TrackFormStarted: React.FC<{
  isDirty: boolean;
  formId: Parameters<typeof generateFormEventTrackingObject>[1];
  formName: Parameters<typeof generateFormEventTrackingObject>[2];
}> = ({ isDirty, formId, formName }) => {
  const { tag } = useTrackingContext();
  const [isTracked, setIsTracked] = React.useState(false);

  React.useEffect(() => {
    if (!tag || isTracked || !isDirty) return;

    const eventObject = generateFormEventTrackingObject('form_start', formId, formName);
    tag(eventObject);
    setIsTracked(true);
  }, [isDirty, isTracked, tag, formId, formName]);
  return null;
};

export const TrackFormSubmitted: React.FC<{
  isSubmitted: boolean;
  formId: Parameters<typeof generateFormEventTrackingObject>[1];
  formName: Parameters<typeof generateFormEventTrackingObject>[2];
}> = ({ isSubmitted, formId, formName }) => {
  const { tag } = useTrackingContext();
  const [isTracked, setIsTracked] = React.useState(false);

  React.useEffect(() => {
    if (!tag || isTracked || !isSubmitted) return;

    const eventObject = generateFormEventTrackingObject('form_submit', formId, formName);
    tag(eventObject);
    setIsTracked(true);
  }, [isSubmitted, isTracked, tag, formId, formName]);
  return null;
};
