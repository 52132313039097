import React, { InputHTMLAttributes } from 'react';

import Mask from './Mask';

const HoneyPotInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
  return (
    <Mask as="p">
      <label htmlFor={props.id}>Ne remplissez pas ce champ si vous êtes humain</label>
      <input {...props} type="text" tabIndex={-1} />
    </Mask>
  );
};

export default HoneyPotInput;
