import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';

export enum BREAKPOINTS {
  xs = '36rem',
  s = '48rem',
  m = '62rem',
  l = '75rem',
}

export const theme = {
  baseline: {
    m: { fontSize: 16, lineHeight: 22 / 16 },
    l: { fontSize: 18, lineHeight: 24 / 18 },
  },
  color: {
    green: {
      m: '#418018',
      s: '#c9ce06',
    },
    gray: {
      l: '#626262',
      m: '#858585',
      s: '#c7c7c7',
    },
    red: {
      m: '#ca1111',
    },
  },
  timing: {
    m: '0.4s',
    s: '0.2s',
  },
  hachure: {
    s: 'rgba(255, 255, 255, 0.12)',
    m: 'rgb(0,0,0,0.16)',
    l: 'rgba(0, 0, 0, 0.36)',
  },
};

export function baseline(
  fontSize: number,
  ratio: number,
  baselineSize: keyof typeof theme.baseline
): string {
  return `
    font-size: ${fontSize / theme.baseline[baselineSize].fontSize}rem;
    line-height: calc(var(--fontSize) * var(--lineHeight) * ${ratio} / ${fontSize});
  `;
}

export function scale(ratio: number): string {
  return `calc(var(--lineHeight) * ${ratio} * 1rem)`;
}

export function hachureStyles(color: string): SerializedStyles {
  return css`
    position: relative;
    overflow: hidden;

    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0,
      transparent 6px,
      ${color} 6px,
      ${color} 7px
    );
    background-position: 0px 0px;
    background-repeat: no-repeat;
  `;
}

export { useTheme } from '@emotion/react';

export const zoneColors = ['#2a520f', '#418018', '#4e9a1e', '#5bc12b'];

export default styled;
