import React from 'react';
import { SerializedStyles } from '@emotion/react';

import styled, { baseline, BREAKPOINTS, scale, hachureStyles } from '../styled';

const Layout = styled.div`
  margin-bottom: ${scale(1)};
  ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
`;

const List = styled.ul`
  padding: ${scale(1)};
  padding-left: ${scale(2)};
`;

const Item = styled.li`
  color: ${({ theme }): string => theme.color.red.m};

  &:before {
    background-color: ${({ theme }): string => theme.color.red.m};
  }

  > a {
    ${baseline(14, 1, 'm')};

    font-weight: normal;
    color: ${({ theme }): string => theme.color.red.m};

    @media (min-width: ${BREAKPOINTS.m}) {
      ${baseline(16, 1, 'l')};
    }

    &:hover {
      color: #000;
    }
  }
`;

const ErrorAlertBox: React.FC = ({ children, ...props }) => {
  return (
    <Layout {...props}>
      <List role="alert">
        {React.Children.map(children, (child) => {
          if (!child) return null;

          return <Item>{child}</Item>;
        })}
      </List>
    </Layout>
  );
};

export default ErrorAlertBox;
