export function loadGoogleMapsLibrary(onLoad: () => void): void {
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src =
    '//maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=AIzaSyDAqxOkRnLk13Z-sD5dy5uQWbQ2_KLS1m0';
  script.addEventListener(`load`, onLoad);

  document.head.append(script);
}
