import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconMapMarker: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M50 1.8c-18.1 0-32.8 14.7-32.8 32.8 0 17.3 29.3 58.8 30.6 60.5l2.2 3.2 2.2-3.2c1.2-1.8 30.6-43.2 30.6-60.5C82.8 16.5 68.1 1.8 50 1.8zm0 86.9c-9.3-13.6-27.3-42.2-27.3-54.1C22.7 19.5 34.9 7.3 50 7.3s27.3 12.3 27.3 27.3c0 11.8-18 40.5-27.3 54.1z" />
      <path d="M50 19c-7.9 0-14.3 6.4-14.3 14.3S42.1 47.6 50 47.6s14.3-6.4 14.3-14.3S57.9 19 50 19zm0 23.1c-4.9 0-8.8-4-8.8-8.8s4-8.8 8.8-8.8 8.8 4 8.8 8.8-3.9 8.8-8.8 8.8z" />
    </svg>
  );
};

export default IconMapMarker;
