import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconInstagram: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M50 26.9c-12.8 0-23.1 10.4-23.1 23.1S37.2 73.1 50 73.1c12.7 0 23-10.4 23-23.1S62.7 26.9 50 26.9zm0 40.7c-9.7 0-17.6-7.9-17.6-17.6 0-9.7 7.9-17.6 17.6-17.6 9.7 0 17.5 7.9 17.5 17.6 0 9.7-7.8 17.6-17.5 17.6zM76.9 16.3c-1.8 0-3.5.7-4.7 2-1.3 1.3-2 2.9-2 4.7s.7 3.5 2 4.7c1.3 1.3 2.9 2 4.7 2s3.5-.7 4.7-2c1.2-1.2 2-2.9 2-4.7v-.1c0-1.7-.8-3.4-2-4.6-1-1.4-2.9-2-4.7-2z" />
      <path d="M76.1 1H23.9C11.3 1 1 11.3 1 23.9v52.3C1 88.7 11.3 99 23.9 99h52.3C88.7 99 99 88.7 99 76.1V23.9C99 11.3 88.7 1 76.1 1zm17.4 75.1c0 9.6-7.8 17.4-17.4 17.4H23.9c-9.6 0-17.4-7.8-17.4-17.4V23.9c0-9.6 7.8-17.4 17.4-17.4h52.3c9.6 0 17.4 7.8 17.4 17.4v52.2z" />
    </svg>
  );
};

export default IconInstagram;
