import React from 'react';

import styled, { scale } from '../styled';

import Mask from './Mask';
import { buttonStyles } from './Button';
import OutboundLink from './OutboundLink';

const Link = styled(OutboundLink)`
  ${buttonStyles};
  display: inline-flex;
  text-decoration: none;
  padding-left: ${scale(0.5)};
  padding-right: ${scale(0.5)};
  width: auto;

  border-radius: 50%;
  line-height: 1;
`;

const SocialIconLink: React.FC<
  JSX.IntrinsicElements['a'] & {
    icon: JSX.Element;
  }
> = ({ href, icon, children, ...props }) => {
  return (
    <Link {...props} href={href}>
      {icon}
      <Mask>{children}</Mask>
    </Link>
  );
};

export default SocialIconLink;
