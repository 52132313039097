import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Link } from 'gatsby';

import { scale, BREAKPOINTS } from '../../../../styled';
import { HEADER_TRANSITION_DURATION } from '..';

import Brand from './Brand';

const BRAND_TEXT_HEIGHT = scale(1.5);
const LOGO_WIDTH = scale(6);

const YOLOLogoStyle = ({
  isHomePage,
  headerIsSticky,
}: {
  isHomePage: boolean;
  headerIsSticky: boolean;
}): SerializedStyles => {
  if (!isHomePage) return css``;

  return css`
    &:after {
      content: '';
      width: ${scale(7.5)};
      height: ${scale(7.5)};
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(${headerIsSticky ? '0' : '1'});
      z-index: -1;
      opacity: ${headerIsSticky ? '0' : '1'};

      transition: transform 0.2s, opacity ${HEADER_TRANSITION_DURATION};
      will-change: transform, opacity;

      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.29 202.69"><polygon points="101.15 0 107.21 5.02 113.85 0.8 119.23 6.54 126.35 3.18 130.97 9.55 138.45 7.12 142.25 14.01 149.97 12.54 152.87 19.85 160.72 19.35 162.67 26.98 170.52 27.47 171.51 35.27 179.24 36.75 179.24 44.61 186.72 47.04 185.73 54.85 192.85 58.2 190.89 65.81 197.53 70.03 194.63 77.34 200.7 82.36 196.91 89.25 202.29 94.98 197.67 101.35 202.29 107.71 196.91 113.44 200.7 120.34 194.63 125.35 197.53 132.66 190.89 136.88 192.85 144.5 185.73 147.85 186.72 155.65 179.24 158.08 179.24 165.95 171.51 167.42 170.52 175.22 162.67 175.72 160.72 183.34 152.87 182.84 149.97 190.16 142.25 188.68 138.45 195.58 130.97 193.14 126.35 199.51 119.23 196.16 113.85 201.89 107.21 197.68 101.15 202.69 95.09 197.68 88.44 201.89 83.06 196.16 75.94 199.51 71.32 193.14 63.84 195.58 60.05 188.68 52.32 190.16 49.43 182.84 41.58 183.34 39.62 175.72 31.77 175.22 30.79 167.42 23.06 165.95 23.06 158.08 15.58 155.65 16.56 147.85 9.45 144.5 11.4 136.88 4.76 132.66 7.66 125.35 1.59 120.34 5.39 113.44 0 107.71 4.63 101.35 0 94.98 5.39 89.25 1.59 82.36 7.66 77.34 4.76 70.03 11.4 65.81 9.45 58.2 16.56 54.85 15.58 47.04 23.06 44.61 23.06 36.75 30.79 35.27 31.77 27.47 39.62 26.98 41.58 19.35 49.43 19.85 52.32 12.54 60.05 14.01 63.84 7.12 71.32 9.55 75.94 3.18 83.06 6.54 88.44 0.8 95.09 5.02 101.15 0" style="fill:%23fff"/></svg>');
    }
  `;
};

const Logo: React.FC<{ isHomePage: boolean; headerIsSticky: boolean }> = ({
  headerIsSticky,
  isHomePage,
  ...props
}) => {
  return (
    <Link
      {...props}
      css={css`
        position: relative;

        padding-bottom: ${headerIsSticky ? '0' : BRAND_TEXT_HEIGHT};
        width: ${LOGO_WIDTH};

        transition-property: padding-bottom;
        transition-duration: ${HEADER_TRANSITION_DURATION};
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        will-change: padding-bottom;

        @media (min-width: ${BREAKPOINTS.m}) {
          ${YOLOLogoStyle({ isHomePage, headerIsSticky })};
        }
      `}
      to="/"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1126.67 329.07"
        css={css`
          display: block;
          margin: 0 auto;

          width: ${scale(6)};
          height: ${scale(2)};
        `}
      >
        <path d="M653 54.12s50.86 56.25 114.37 0c.02 0-51.45-71.46-114.37 0z" fill="#c1ce30" />
        <path
          d="M404.85 212.72a106.79 106.79 0 11-106.79-106.8 106.79 106.79 0 01106.79 106.8z"
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="16"
        />
        <circle
          cx="827.61"
          cy="212.72"
          r="106.79"
          transform="rotate(-15.23 827.764 212.726)"
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="16"
        />
        <path
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="15"
          d="M412.94 115.6h298.54v188.52H412.94z"
        />
        <path
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="15"
          d="M412.94 115.59l149.28 112.19 149.27-112.19M412.94 304.12L529.9 203.5M711.49 304.12L594.53 203.5"
        />
        <path
          d="M289.56 222.56L466.47 25.25s12.11-16 32.92-16h33.91"
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="16"
        />
        <path
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="15"
          d="M711.49 84.37v36.24l124.12 100.32"
        />
        <path
          d="M653 54.12s18 25.62 58.47 25.62 55.9-25.62 55.9-25.62-9.25-29.51-57.19-29.51C663.3 24.61 653 54.12 653 54.12zM688.55 52.18h106.99"
          fill="none"
          stroke="#222221"
          strokeMiterlimit="10"
          strokeWidth="12"
        />
        <path
          fill="#222221"
          d="M.002 215.883l23.335-23.334 23.335 23.334-23.335 23.335zM1079.988 215.858l23.334-23.334 23.335 23.334-23.335 23.335z"
        />
      </svg>
      <Brand
        css={css`
          position: absolute;
          bottom: 0;
          width: ${LOGO_WIDTH};
          height: ${BRAND_TEXT_HEIGHT};

          height: ${headerIsSticky ? '0' : BRAND_TEXT_HEIGHT};
          opacity: ${headerIsSticky ? '0' : '1'};

          transition-property: height, opacity;
          transition-duration: 0.6s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-delay: ${headerIsSticky ? '0s' : '0.2s'};
          will-change: height, opacity;
        `}
      />
    </Link>
  );
};

export default Logo;
