import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconPhoneHandset: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M74.8 98.6h-1.3c-7-.3-18.9-5.3-19.4-5.5H54c-10-4.7-19.2-11.3-27.4-19.5-2-2-4-4.1-6.1-6.5-8-9.6-13.1-18.3-16.3-27.4-.9-2.4-1.5-4.6-2-6.6-1.4-5.9-.6-12.1 2-17.5C5 14 5.8 12.9 6.6 12l6.8-6.8c2.1-2.4 5.3-3.8 8.6-3.8h.3c3.4.2 6.2 1.4 8.6 3.8l11.4 11.4c5.3 5.3 5.3 12.2 0 17.6l-4.6 4.6L61 62.1l4.8-4.8c.9-.9 3.8-3.8 8.8-3.8 3.9 0 6.8 2.2 8.6 3.8l.1.1 5.7 5.7c2.9 2.9 5.7 5.6 5.8 5.6 4.5 4.5 4.9 10.9 1.1 16.9-4.7 6.9-14.5 13-21.1 13zm-1.1-5.5h1.2c4.7 0 12.8-5.2 16.4-10.5 2.4-3.7 2.3-7.3-.3-9.9 0 0-2.9-2.7-5.8-5.7l-5.6-5.6c-.8-.7-2.7-2.4-4.9-2.4-2.8 0-4.2 1.5-5 2.2L64.8 66c-1 1-2.4 1.6-3.9 1.6S58.1 67 57 66L33.8 42.6c-2.1-2.1-2.1-5.6 0-7.7l4.6-4.6c3.1-3.2 3.1-6.8 0-9.9L27.1 9.1c-1.3-1.3-2.9-2.1-4.9-2.2h-.1c-1.8 0-3.4.7-4.5 2l-.1.2-7 6.9c-.2.2-.7.8-1.4 2.1-2.1 4.2-2.6 9.1-1.6 13.8.4 1.8 1 3.7 1.8 5.9 3 8.5 7.9 16.6 15.4 25.7 1.9 2.1 3.9 4.3 5.8 6.2 7.7 7.7 16.4 13.9 25.8 18.4 3.2 1.3 12.4 4.8 17.4 5z" />
    </svg>
  );
};

export default IconPhoneHandset;
