import React from 'react';
import { css } from '@emotion/react';

import { useTheme, scale as baselineScale } from '../../styled';

const IconBicycle: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  const theme = useTheme();

  return (
    <svg
      {...props}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 121 100"
      xmlSpace="preserve"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1.25 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <circle fill={theme.color.green.s} cx="100.4" cy="68.6" r="18.4" />
      <circle fill={theme.color.green.s} cx="32.9" cy="68.6" r="18.4" />
      <path d="M-11.7 60.5H8.6c1.1 0 2-.9 2-2s-.9-2-2-2h-20.3c-1.1 0-2 .9-2 2s.9 2 2 2zM7.6 68.4c0-1.1-.9-2-2-2h-27.5c-1.1 0-2 .9-2 2s.9 2 2 2H5.6c1.1 0 2-.9 2-2zM8.9 76.8h-21.6c-1.1 0-2 .9-2 2s.9 2 2 2H8.9c1.1 0 2-.9 2-2s-.9-2-2-2z" />
      <g>
        <circle transform="rotate(-45.001 71.35 18.3)" cx="71.4" cy="18.3" r="9.4" />
        <path d="M100.4 48.2c-4.3 0-8.3 1.3-11.6 3.6l-4.9-5.7c.6-.4 1.1-.9 1.4-1.6 1.4-2.4.5-5.5-1.9-6.8L64.2 27c-2.2-1.2-4.9-.7-6.4 1.3l-15 19.6c-.5.6-.8 1.3-.9 2-.2 0-.3.1-.5.1-2.6-1.2-5.5-1.9-8.5-1.9-11.3 0-20.4 9.1-20.4 20.4 0 11.3 9.1 20.4 20.4 20.4 10.5 0 19.3-8 20.3-18.4h6.4l-.3 6.8c-.1 2.7 2 5.1 4.8 5.2h.2c2.7 0 4.9-2.1 5.2-4.8l.4-8L85.5 54l.3.3c-3.5 3.7-5.7 8.7-5.7 14.2C80 79.9 89.2 89 100.4 89c11.3 0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4zM49.1 66.6H38l8.2-7.5c1.5 2.1 2.6 4.7 2.9 7.5zM63 37.8l15.4 8.6.6.3 2.7 3.2H54.5l-.5-.3 9-11.8zM32.9 85c-9.1 0-16.4-7.4-16.4-16.4s7.3-16.3 16.4-16.3c4 0 7.8 1.5 10.6 3.9l-12 10.9c-.6.6-.8 1.4-.5 2.2s1.1 1.3 1.9 1.3h16.3C48.1 78.7 41.3 85 32.9 85zm26.9-18.4h-6.6c-.2-2.4-.9-4.7-1.9-6.8l8.6 5.6-.1 1.2zm8.1-8.2l-7.1-4.5H80l-9.9 10v-1c.2-1.8-.7-3.5-2.2-4.5zM100.4 85c-9 0-16.4-7.2-16.4-16.3 0-4.3 1.7-8.2 4.4-11.1l10.5 12.3c.4.5.9.7 1.5.7.5 0 1-.1 1.4-.4.8-.7.9-2 .2-2.8L91.4 55c2.6-1.7 5.7-2.7 9-2.7 9.1 0 16.4 7.2 16.4 16.3 0 9.1-7.4 16.4-16.4 16.4z" />
      </g>
    </svg>
  );
};

export default IconBicycle;
