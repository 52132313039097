import React from 'react';
import { css } from '@emotion/react';

import CenteredBox from '../../CenteredBox';
import { scale, BREAKPOINTS, useTheme } from '../../../styled';
import { supportsPassiveEvents } from '../../../featureDetection';

import Logo from './Logo';
import Menu from './Menu';

export const HEADER_TRANSITION_DURATION = '0.8s';

const Header: React.FC<{ isHomePage: boolean }> = ({ isHomePage }) => {
  const [isSticky, setSticky] = React.useState(false);
  const theme = useTheme();

  const handleScroll = React.useCallback(function (this: Window): void {
    setSticky(this.scrollY > 0);
  }, []);

  React.useEffect(() => {
    /* istanbul ignore next */
    const options = supportsPassiveEvents() ? { passive: true } : false;
    window.addEventListener('scroll', handleScroll, options);

    return (): void => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <header
      data-testid="header"
      data-test-is-sticky={isSticky}
      css={css`
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;

        background-color: #fff;

        transition-property: background-color;
        transition-duration: ${theme.timing.s};
        will-change: background-color;

        @media (min-width: ${BREAKPOINTS.m}) {
          background-color: ${isSticky ? '#fff' : isHomePage ? 'transparent' : '#fff'};
        }
      `}
    >
      <CenteredBox
        as="div"
        role="banner"
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;

          padding-top: ${isSticky ? scale(0) : scale(0.25)};
          padding-bottom: ${isSticky ? scale(0) : scale(0.25)};

          transition-property: padding-top, padding-bottom;
          transition-duration: ${HEADER_TRANSITION_DURATION};
          will-change: padding-top, padding-bottom;

          @media (min-width: ${BREAKPOINTS.m}) {
            padding-top: ${isSticky ? scale(0) : scale(2)};
            padding-bottom: ${isSticky ? scale(0) : scale(2)};
          }
        `}
      >
        <Logo isHomePage={isHomePage} headerIsSticky={isSticky} />
        <Menu isHomePage={isHomePage} headerIsSticky={isSticky} />
      </CenteredBox>
    </header>
  );
};

export default Header;
