import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconMail: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M93.7 22.5H6.3c-2.6 0-4.8 2.1-4.8 4.8v45.5c0 2.6 2.1 4.8 4.8 4.8h87.5c2.6 0 4.8-2.1 4.8-4.8V27.3c-.1-2.7-2.2-4.8-4.9-4.8zM50 48.7L13.7 28h72.6L50 48.7zm-12.1-.5L7 69.5v-39l30.9 17.7zm5.3 3l4.4 2.5c.7.4 1.5.6 2.4.6.8 0 1.6-.2 2.4-.6l4.4-2.5 30 20.8H13.1l30.1-20.8zm18.8-3l31-17.7v39.1L62 48.2z" />
    </svg>
  );
};

export default IconMail;
