import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconPackage: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M96.6 24.7c0-.1 0-.1 0 0 0-.2 0-.4-.1-.5v-.1c0-.1-.1-.3-.2-.4-.1-.1-.2-.3-.3-.4l-.1-.1c-.1-.1-.2-.2-.4-.2h-.1L50.7 2c-.5-.3-1.2-.3-1.7 0L33.2 9.4l-8.8 4.2-19.8 9.3s-.1 0-.1.1h-.1-.1c-.1.1-.2.1-.3.2 0 0-.1 0-.1.1-.1.1-.2.2-.3.4v.1c-.1.1-.1.2-.1.3V73c0 .7.4 1.4 1.1 1.8l44.4 23c.1 0 .2.1.3.1s.1 0 .2.1c.2 0 .3.1.5.1s.3 0 .5-.1c.1 0 .1 0 .2-.1.1 0 .2-.1.3-.1l44.7-23.1c.7-.3 1.1-1 1.1-1.8l-.2-48.2zM49.8 6L90 24.8l-13 6.8-38.5-20.3L49.8 6zM25.2 17.6l39.4 20.6L49.8 46 9.9 24.8l15.3-7.2zm8.8-4.1l38.8 20.4-4 2.1-39.1-20.5 4.3-2zm36.8 26l4.3-2.2v43.4l-4.3 2V39.5zm-19 10l15-7.9v43.3l-15 7.7V49.5zm-4 43.2l-40.4-21V28l40.4 21.4v43.3zm31.3-14.1V35.1L92.6 28v43.6l-13.5 7z" />
      <path d="M12.9 47.5l7.9 4.2c.3.2.6.2.9.2.4 0 .7-.1 1-.3.6-.4 1-1 1-1.7v-8.3c0-.7-.4-1.4-1.1-1.8l-7.9-4.1c-.6-.3-1.4-.3-2 .1-.6.4-1 1-1 1.7v8.2c.1.8.5 1.5 1.2 1.8zm2.9-6.7l3.9 2v3.8l-3.9-2.1v-3.7zM12.9 61.4l7.9 4.2c.3.2.6.2.9.2.4 0 .7-.1 1-.3.6-.4 1-1 1-1.7v-8.3c0-.7-.4-1.4-1.1-1.8l-7.9-4.1c-.6-.3-1.4-.3-2 .1-.6.4-1 1-1 1.7v8.2c.1.8.5 1.5 1.2 1.8zm2.9-6.7l3.9 2v3.8l-3.9-2.1v-3.7z" />
    </svg>
  );
};

export default IconPackage;
