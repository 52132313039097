import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';

import * as GatsbyTypes from '../graphqlTypes';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PageTitle from '../components/PageTitle';
import ContactMap, { OFFICE_GEO } from '../components/ContactMap';
import ContactInformation from '../components/ContactInformation';
import ContactForm from '../components/ContactForm';
import { BREAKPOINTS } from '../styled';

export enum SocialNetworkLinks {
  Facebook = 'https://fr-fr.facebook.com/GreenCourse44/',
  Instagram = 'https://www.instagram.com/green_course/',
}

const ContactPage: React.FC = () => {
  const { file } = useStaticQuery<GatsbyTypes.GetContactPageQuery>(
    graphql`
      query GetContactPage {
        file(name: { eq: "contact" }) {
          id
          childMarkdownRemark {
            id
            frontmatter {
              title
              phoneNumber
              email
              openingHours {
                days
                openingTime
                closingTime
              }
              seo {
                title
                description
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  );

  const seoData = file?.childMarkdownRemark?.frontmatter?.seo;
  /* istanbul ignore next */
  if (!seoData?.title || !seoData.description) {
    throw new Error('[Missing data] Page SEO meta');
  }

  const pageData = file?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.title || !pageData?.phoneNumber || !pageData?.email || !pageData?.openingHours) {
    throw new Error('[Missing data] Page content');
  }

  const openingHours = pageData.openingHours as GatsbyTypes.MarkdownRemarkFrontmatterOpeningHours[];

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            image: [
              'https://www.greencourse.fr/images/un-coursier-a-velo-pour-vos-plis-et-colis.jpg',
            ],
            '@id': 'https://www.greencourse.fr/',
            name: 'Greencourse',
            logo: 'https://www.greencourse.fr/images/logo.png',
            address: {
              '@type': 'PostalAddress',
              streetAddress: '5 Allée du Colvert',
              addressLocality: 'Nantes',
              addressRegion: 'Pays de la Loire',
              postalCode: '44000',
              addressCountry: 'FR',
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: OFFICE_GEO.LAT,
              longitude: OFFICE_GEO.LNG,
            },
            url: 'https://www.greencourse.fr/contact',
            telephone: `+33${pageData.phoneNumber.substr(1)}`,
            email: pageData.email,
            priceRange: '$',
            currenciesAccepted: 'EUR',
            paymentAccepted: ['Cash, Credit Card'],
            areaServed: {
              '@type': 'AdministrativeArea',
              address: {
                '@type': 'PostalAddress',
                addressLocality: 'Nantes',
                addressRegion: 'Pays de la Loire',
                addressCountry: 'FR',
              },
            },
            openingHoursSpecification: openingHours.map((openingHour) => {
              /* istanbul ignore next */
              if (!openingHour?.openingTime || !openingHour?.closingTime || !openingHour?.days) {
                throw new Error('[Missing data] Opening hours');
              }

              return {
                '@type': 'OpeningHoursSpecification',
                dayOfWeek: openingHour.days,
                opens: openingHour.openingTime.replace('h', ':'),
                closes: openingHour.closingTime.replace('h', ':'),
              };
            }),
            sameAs: [SocialNetworkLinks.Facebook, SocialNetworkLinks.Instagram],
          })}
        </script>
      </Helmet>
      <SEO
        title={seoData.title}
        description={seoData.description}
        image={seoData.image?.publicURL}
      />
      <PageTitle>{pageData.title}</PageTitle>
      <ContactMap />
      <div
        css={css`
          @media (min-width: ${BREAKPOINTS.m}) {
            display: flex;
          }
        `}
      >
        <div
          css={css`
            @media (min-width: ${BREAKPOINTS.m}) {
              width: 45%;
            }
          `}
        >
          <ContactInformation
            phoneNumber={pageData.phoneNumber}
            email={pageData.email}
            openingHours={openingHours}
          />
        </div>
        <div
          css={css`
            @media (min-width: ${BREAKPOINTS.m}) {
              width: 55%;
            }
          `}
        >
          <ContactForm phoneNumber={pageData.phoneNumber} />
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
