import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as GatsbyTypes from '../../../../graphqlTypes';
import { IconPhoneHandset } from '../../../icons';
import styled, { scale } from '../../../../styled';
import { buttonStyles } from '../../../Button';

const Link = styled.a`
  ${buttonStyles};
  display: inline-flex;
  text-decoration: none;
`;

const Icon = styled(IconPhoneHandset)`
  margin-right: ${scale(0.75)};
`;

const PhoneButton: React.FC = () => {
  const { phoneNumberFile } = useStaticQuery<GatsbyTypes.GetPhoneNumberQuery>(
    graphql`
      query GetPhoneNumber {
        phoneNumberFile: file(name: { eq: "contact" }) {
          id
          childMarkdownRemark {
            id
            frontmatter {
              phoneNumber
            }
          }
        }
      }
    `
  );

  const pageData = phoneNumberFile?.childMarkdownRemark?.frontmatter;
  /* istanbul ignore next */
  if (!pageData?.phoneNumber) {
    throw new Error('[Missing data] Phone number');
  }

  return (
    <Link href={`tel:+33${pageData.phoneNumber.substr(1)}`}>
      <Icon />
      {pageData.phoneNumber.replace(/\d{2}(?=.)/g, '$& ')}
    </Link>
  );
};

export default PhoneButton;
