import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconBulkyDelivery: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 490 490"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <path d="M471.257 17.263 460.245 0 347.609 71.794 232.09 373.632c-13.43 1.01-25.605 6.578-34.968 15.176L26.245 321.589l-7.502 19.063 165.588 65.139a57.893 57.893 0 0 0-6.109 25.928c0 32.136 26.14 58.281 58.271 58.281s58.275-26.146 58.275-58.281c0-26.336-17.574-48.639-41.607-55.828l111.16-290.455 106.936-68.173zM274.285 431.719c0 20.844-16.953 37.797-37.791 37.797-20.834 0-37.787-16.953-37.787-37.797 0-20.833 16.953-37.787 37.787-37.787 20.838 0 37.791 16.954 37.791 37.787z" />
      <path d="m47.449 300.815 157.923 61.122c6.471 2.363 11.677-1.79 13.248-5.851l56.15-145.077c1.314-2.565 1.62-10.123-5.857-13.252l-157.924-61.122c-5.271-2.04-11.202.57-13.248 5.851l-56.15 145.076c-2.721 7.919 3.322 12.273 5.858 13.253zm65.702-141.376 138.821 53.72-48.759 125.973-138.82-53.72 48.758-125.973zm4.94-37.987 157.925 61.122c6.524 2.387 11.677-1.79 13.247-5.851l26.66-68.883c1.084-2.367 1.761-10-5.856-13.253L152.143 33.466c-5.276-2.04-11.207.58-13.247 5.851l-26.66 68.882c-2.764 7.864 3.175 12.346 5.855 13.253zm36.213-65.182 138.82 53.721-19.269 49.778-138.82-53.72 19.269-49.779z" />
    </svg>
  );
};

export default IconBulkyDelivery;
