import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconChevronLeft: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M70.4 2.4L26.2 46.8c-.9.9-1.3 2.1-1.3 3.3s.5 2.4 1.3 3.3l44.2 44.2c1.1 1.1 2.8 1.1 3.9 0 .5-.5.8-1.2.8-1.9s-.3-1.4-.8-1.9L30.7 50.2 74.3 6.3c1.1-1.1 1.1-2.8 0-3.9s-2.8-1.1-3.9 0z" />
    </svg>
  );
};

export default IconChevronLeft;
