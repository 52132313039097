import React from 'react';
import { css } from '@emotion/react';

import { scale as baselineScale } from '../../styled';

const IconArrowRight: React.FC<React.SVGProps<SVGSVGElement> & { scale?: number }> = ({
  scale = 1,
  children,
  ...props
}) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="currentColor"
      css={css`
        width: ${baselineScale(1 * scale)};
        height: ${baselineScale(1 * scale)};
      `}
    >
      <title>{children}</title>
      <path d="M67.6 42.4l4.8 4.8H21c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8h51.4l-4.8 4.8c-1.1 1.1-1.1 2.8 0 3.9s2.8 1.1 3.9 0L82.9 50 71.5 38.6c-1.1-1.1-2.8-1.1-3.9 0s-1.1 2.8 0 3.8z" />
      <path d="M2.3 50c0 26.3 21.4 47.8 47.8 47.8 26.3 0 47.8-21.4 47.8-47.8C97.8 23.7 76.3 2.3 50 2.3 23.7 2.3 2.3 23.7 2.3 50zm90 0c0 23.3-19 42.3-42.3 42.3S7.8 73.3 7.8 50 26.7 7.8 50 7.8 92.3 26.7 92.3 50z" />
    </svg>
  );
};

export default IconArrowRight;
