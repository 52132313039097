import React, { HTMLAttributes } from 'react';
import { SerializedStyles, css, Theme } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import { Link } from 'gatsby';

import styled, { scale, baseline, BREAKPOINTS, hachureStyles } from '../../../../styled';

import ToggleButton from './ToggleButton';
import PhoneButton from './PhoneButton';
import ServicesLinks from './ServicesLinks';

const List = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  height: 100%;
  overflow-x: scroll;
  ${baseline(20, 1, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    display: inline-flex;
    align-items: center;
    height: auto;
    margin-right: ${scale(-0.5)};
    margin-left: ${scale(-0.5)};
    overflow-x: auto;

    ${baseline(16, 1, 'l')};
  }
`;

export function activeLinkStyles({
  theme,
  isHomePage,
  headerIsSticky,
}: {
  theme: Theme;
  isHomePage: boolean;
  headerIsSticky: boolean;
}): SerializedStyles {
  return css`
    color: ${headerIsSticky ? theme.color.green.m : isHomePage ? '#fff' : theme.color.green.m};

    &:before {
      background-color: ${headerIsSticky
        ? theme.color.green.m
        : isHomePage
        ? '#fff'
        : theme.color.green.m};
      transition-delay: 0s;

      bottom: ${scale(1.25)};
      opacity: 1;
    }

    &:after {
      background-color: ${headerIsSticky
        ? theme.color.green.m
        : isHomePage
        ? '#fff'
        : theme.color.green.m};
      transition-delay: ${theme.timing.s};

      bottom: ${scale(1.125)};
      opacity: 1;
    }
  `;
}

const Item = styled.li`
  position: initial;
  margin-top: ${scale(0.5)};
  margin-bottom: ${scale(0.5)};

  text-align: center;
  overflow: hidden;

  @media (min-width: ${BREAKPOINTS.m}) {
    margin: 0 ${scale(0.5)};

    text-align: inherit;
  }

  &:before {
    display: none;
  }

  &:last-child {
    @media (min-width: ${BREAKPOINTS.m}) {
      margin-left: ${scale(1.5)};
    }
  }

  &:hover {
    @media (min-width: ${BREAKPOINTS.m}) {
      > ul {
        height: ${scale(3)};
        box-shadow: 0px 0px 0px 1px ${({ theme }): string => theme.color.gray.s};
      }
    }
  }
`;

const ItemLink = styled('a', { shouldForwardProp: isPropValid })<{
  as: React.ElementType;
  to: string;
  isHomePage: boolean;
  headerIsSticky: boolean;
}>`
  position: relative;
  display: block;
  padding-top: ${scale(0.5)};
  padding-bottom: ${scale(0.5)};

  color: #000;
  background-color: transparent;
  text-decoration: none;

  transition: color ${({ theme }): string => theme.timing.s},
    background-color ${({ theme }): string => theme.timing.s};
  will-change: color, background-color;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: ${scale(1.5)};
    padding-bottom: ${scale(1.5)};
    color: ${({ isHomePage, headerIsSticky }): string =>
      headerIsSticky ? '#000' : isHomePage ? '#fff' : '#000'};
  }

  &:hover {
    color: #fff;
    background-color: ${({ theme }): string => theme.color.green.m};

    @media (min-width: ${BREAKPOINTS.m}) {
      color: ${({ theme }): string => theme.color.green.m};
      background-color: transparent;
    }
  }

  &:before {
    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${scale(1.5)};
      display: block;
      width: 100%;
      height: 1px;
      background-color: #000;

      opacity: 0;
      transition: bottom 0.2s, opacity 0.16s, background-color 0.16s;
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      will-change: bottom, background-color, opacity;
    }
  }

  &:after {
    display: none;

    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      display: initial;
      position: absolute;
      left: 0;
      right: 0;
      bottom: ${scale(1.25)};
      width: 100%;
      height: 1px;

      background-color: #000;

      opacity: 0;
      transition: bottom 0.2s, opacity 0.16s, background-color 0.16s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      will-change: background-color, opacity;
    }
  }

  &[aria-current],
  &:hover {
    @media (min-width: ${BREAKPOINTS.m}) {
      ${activeLinkStyles};
    }
  }
`;

const Layout = styled.nav<HTMLAttributes<HTMLElement> & { isVisible: boolean }>`
  position: absolute;
  top: 100%;
  padding: ${scale(1)};
  right: ${({ isVisible }): string => {
    /* istanbul ignore next */
    return isVisible ? '0' : '-100%';
  }};
  width: 100%;
  height: calc(100vh - 100%);
  box-sizing: border-box;

  background-color: #fff;

  transition-property: right;
  transition-duration: 1s;
  will-change: right;

  @media (min-width: ${BREAKPOINTS.m}) {
    position: initial;
    top: auto;
    right: auto;
    width: auto;
    min-height: auto;
    padding: 0;

    background-color: transparent;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:before,
  &:after {
    content: '';
    ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: ${scale(0.5)};

    @media (min-width: ${BREAKPOINTS.m}) {
      display: none;
    }
  }
`;

const Menu: React.FC<{ headerIsSticky: boolean; isHomePage: boolean }> = ({
  headerIsSticky,
  isHomePage,
}) => {
  const [isVisible, setVisibility] = React.useState(false);

  const handleClick = React.useCallback(
    /* istanbul ignore next */
    (value: boolean) => (): void => {
      setVisibility(value);
      document.documentElement.classList.toggle('menu-is-opened');
    },
    [setVisibility]
  );

  React.useEffect(function onMount() {
    return function onUnMount(): void {
      document.documentElement.classList.remove('menu-is-opened');
    };
  }, []);

  return (
    <div>
      <Layout role="navigation" isVisible={isVisible}>
        <List>
          <Item>
            <ItemLink as={Link} to="/" isHomePage={isHomePage} headerIsSticky={headerIsSticky}>
              Accueil
            </ItemLink>
          </Item>
          <Item>
            <ItemLink
              as={Link}
              to="/propos"
              isHomePage={isHomePage}
              headerIsSticky={headerIsSticky}
            >
              À propos
            </ItemLink>
          </Item>
          <Item>
            <ServicesLinks isHomePage={isHomePage} headerIsSticky={headerIsSticky} />
          </Item>
          <Item>
            <ItemLink
              as={Link}
              to="/tarifs"
              isHomePage={isHomePage}
              headerIsSticky={headerIsSticky}
            >
              Tarifs
            </ItemLink>
          </Item>
          <Item>
            <ItemLink
              as={Link}
              to="/clients"
              isHomePage={isHomePage}
              headerIsSticky={headerIsSticky}
            >
              Clients
            </ItemLink>
          </Item>
          <Item>
            <ItemLink
              as={Link}
              to="/contact"
              isHomePage={isHomePage}
              headerIsSticky={headerIsSticky}
            >
              Contact
            </ItemLink>
          </Item>
          <Item>
            <PhoneButton />
          </Item>
        </List>
      </Layout>
      <ToggleButton onClick={handleClick(!isVisible)} isActive={isVisible}>
        Menu
      </ToggleButton>
    </div>
  );
};

export default Menu;
