import React from 'react';
import { Link } from 'gatsby';
import { Formik, FormikErrors } from 'formik';
import { SerializedStyles } from '@emotion/react';

import styled, { scale, BREAKPOINTS, baseline, hachureStyles } from '../styled';
import {
  validateEmail,
  validatePhoneNumber,
  urlEncode,
  TrackFormStarted,
  TrackFormSubmitted,
} from '../formUtils';
import { useTrackingContext } from '../tracking';

import Input from './Input';
import Textarea from './Textarea';
import Checkbox from './Checkbox';
import Button from './Button';
import HoneyPotInput from './HoneyPotInput';
import ErrorAlertBox from './ErrorAlertBox';
import { CenteredBoxMaxWidth } from './CenteredBox';
import { IconEnvelope, IconSpinner } from './icons';

const Background = styled.div`
  padding: ${scale(2)} ${scale(1)};

  @media (min-width: ${BREAKPOINTS.m}) {
    position: relative;
    height: 100%;
    padding: 0;

    background-color: #fff;
  }

  &:before {
    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.s)};
      background-color: ${({ theme }): string => theme.color.green.m};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: ${scale(6)};
    }
  }

  &:after {
    @media (min-width: ${BREAKPOINTS.m}) {
      content: '';
      ${({ theme }): SerializedStyles => hachureStyles(theme.hachure.m)};
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${scale(6)};
      right: 0;
    }
  }
`;

const Layout = styled.div`
  padding: ${scale(2)} ${scale(1)};
  max-width: ${scale((CenteredBoxMaxWidth.M - 2) * 0.55)};

  @media (min-width: ${BREAKPOINTS.m}) {
    position: absolute;
    bottom: ${scale(2)};
    left: ${scale(2)};
    right: ${scale(1)};
    padding: ${scale(2)};
    box-sizing: border-box;

    background-color: #fff;
    border-radius: ${scale(2)};
    z-index: 1;
  }
`;

const MandatoryMessage = styled.p`
  ${baseline(14, 1, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(14, 1, 'l')};
  }
`;

const SuccessMessage = styled.p`
  color: ${({ theme }): string => theme.color.green.m};

  > svg {
    display: inline-block;
    vertical-align: middle;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  justify-content: center;

  @media (min-width: ${BREAKPOINTS.s}) {
    width: auto;
  }

  > svg {
    margin-right: 0;

    animation: rotating 2s linear infinite;
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const initialValues = {
  honey: '',
  name: '',
  email: '',
  ['phone-number']: '',
  message: '',
  ['accept-privacy-policy']: '',
};

const FORM_ID = 'form-contact';
const FORM_NAME = 'contact';

type ContactFormErrors = FormikErrors<typeof initialValues>;

const ContactForm: React.FC<{ phoneNumber: string }> = (props) => {
  const { tag } = useTrackingContext();

  return (
    <Background>
      <Layout>
        <h2>Contactez-nous</h2>
        <p>Une question ? Contactez-nous par téléphone ou via notre formulaire de contact !</p>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(values): ContactFormErrors => {
            const errors: ContactFormErrors = {};

            if (!values.name) {
              errors.name = 'Le champ "Nom et prénom" est obligatoire.';
            }

            if (!values.email) {
              errors.email = 'Le champ "Adresse E-mail" est obligatoire.';
            } else if (!validateEmail(values.email)) {
              errors.email = "L'adresse e-mail fournis n'est pas valide.";
            }

            if (!values['phone-number']) {
              errors['phone-number'] = 'Le champ "Téléphone" est obligatoire.';
            } else if (!validatePhoneNumber(values['phone-number'])) {
              errors['phone-number'] = "Le numéro de téléphone fournis n'est pas valide.";
            }

            if (!values.message) {
              errors.message = 'Le champ "Message" est obligatoire.';
            }

            if (!values['accept-privacy-policy']) {
              errors['accept-privacy-policy'] =
                'Merci de consentir à notre politique de confidentialité.';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, setStatus }): void => {
            window
              .fetch('/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: urlEncode({ 'form-name': FORM_NAME, ...values }),
              })
              .then(() => {
                setStatus({ isSubmitted: true, hasError: false });
              })
              .catch(() => {
                setStatus({ hasError: true, isSubmitted: false });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            status = { isSubmitted: false, hasError: false },
            handleSubmit,
            handleChange,
            handleBlur,
            dirty,
          }): JSX.Element => {
            return (
              <form
                id={FORM_ID}
                name={FORM_NAME}
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="honey"
                autoComplete="off"
                onSubmit={handleSubmit}
                data-testid="test"
                noValidate
              >
                <TrackFormStarted isDirty={dirty} formId={FORM_ID} formName={FORM_NAME} />
                <TrackFormSubmitted
                  isSubmitted={Boolean(status.isSubmitted)}
                  formId={FORM_ID}
                  formName={FORM_NAME}
                />
                {(Boolean(Object.keys(errors).length) || status.hasError) && (
                  <ErrorAlertBox>
                    {errors.name && <a href="#name">{errors.name}</a>}
                    {errors.email && <a href="#email">{errors.email}</a>}
                    {errors['phone-number'] && <a href="#phone-number">{errors['phone-number']}</a>}
                    {errors.message && <a href="#message">{errors.message}</a>}
                    {errors['accept-privacy-policy'] && (
                      <a href="#accept-privacy-policy">{errors['accept-privacy-policy']}</a>
                    )}
                    {status.hasError && (
                      <React.Fragment>
                        Une erreur réseau est survenu, merci de ressayer plus tard ou nous appelez
                        au{' '}
                        <a href={`tel:+33${props.phoneNumber.substr(1)}`}>
                          {props.phoneNumber.replace(/\d{2}(?=.)/g, '$& ')}
                        </a>
                        .
                      </React.Fragment>
                    )}
                  </ErrorAlertBox>
                )}
                <Input
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-invalid={Boolean(errors.name)}
                  required
                >
                  Nom et prénom <sup>*</sup>
                </Input>
                <HoneyPotInput
                  id="company"
                  name="company"
                  value={values.honey}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-invalid={Boolean(errors.email)}
                  required
                >
                  Adresse E-mail <sup>*</sup>
                </Input>
                <Input
                  id="phone-number"
                  name="phone-number"
                  value={values['phone-number']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-invalid={Boolean(errors['phone-number'])}
                  required
                >
                  Téléphone <sup>*</sup>
                </Input>
                <Textarea
                  id="message"
                  name="message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-invalid={Boolean(errors.message)}
                  required
                >
                  Message <sup>*</sup>
                </Textarea>
                <Checkbox
                  id="accept-privacy-policy"
                  name="accept-privacy-policy"
                  value={values['accept-privacy-policy']}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  aria-invalid={Boolean(errors['accept-privacy-policy'])}
                  required
                >
                  En cliquant vous acceptez que l&apos;information fournie nous soit transmise en
                  accord avec notre{' '}
                  <Link to="/politique-confidentialite">Politique de confidentialité</Link>.
                </Checkbox>
                <MandatoryMessage>
                  Tous les champs sont obligatoires <sup>*</sup>
                </MandatoryMessage>
                {status.isSubmitted && (
                  <SuccessMessage>
                    <IconEnvelope /> Nous reviendrons vers vous rapidement.
                  </SuccessMessage>
                )}
                <SubmitButton
                  type="submit"
                  disabled={isSubmitting || status.isSubmitted || status.hasError}
                >
                  {!status.isSubmitted ? (
                    isSubmitting ? (
                      <IconSpinner />
                    ) : (
                      'Envoyer'
                    )
                  ) : (
                    'Message envoyé'
                  )}
                </SubmitButton>
              </form>
            );
          }}
        </Formik>
      </Layout>
    </Background>
  );
};

export default ContactForm;
