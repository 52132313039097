import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import * as GatsbyTypes from '../graphqlTypes';

const LOCALE = 'fr';

export interface SEOProps {
  title?: string;
  description?: string;
  image?: string | null;
}

const SEO: React.FC<SEOProps> = (props) => {
  const { site } = useStaticQuery<GatsbyTypes.GetDefaultSiteMetaDataQuery>(
    graphql`
      query GetDefaultSiteMetaData {
        site {
          siteMetadata {
            title
            description
            siteUrl
            streetAddress
            locality
            postalCode
            region
            countryName
            email
            phoneNumber
          }
        }
      }
    `
  );

  if (!site || !site.siteMetadata || !site.siteMetadata.description || !site.siteMetadata.title) {
    throw new Error('[Missing data] Site default metadata');
  }

  if (
    !site.siteMetadata.streetAddress ||
    !site.siteMetadata.locality ||
    !site.siteMetadata.region ||
    !site.siteMetadata.locality ||
    !site.siteMetadata.postalCode ||
    !site.siteMetadata.countryName ||
    !site.siteMetadata.email ||
    !site.siteMetadata.phoneNumber
  ) {
    throw new Error('[Missing data] Open Graph contact information');
  }

  const metaDescription = props.description || site.siteMetadata.description;
  const metaTitle = props.title
    ? `${props.title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title;
  const metaImage = props.image || '/images/un-coursier-a-velo-pour-vos-plis-et-colis.jpg';

  return (
    <Helmet>
      {/* html attributes */}
      <html lang={LOCALE} />
      {/* body attributes */}
      <body className="html" />
      {/* encoding meta elements */}
      <meta charSet="utf-8" />
      <meta httpEquiv="content-type" content="text/html; charset=utf-8" />
      {/* responsive meta elements */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      {/* content meta elements */}
      <title itemProp="name" lang={LOCALE}>
        {metaTitle}
      </title>
      <meta name="description" content={metaDescription} />
      <meta httpEquiv="content-language" content={LOCALE} />
      {/* site open graph attributes */}
      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:type" content="website" />
      <meta property="og:street_address" content={site.siteMetadata.streetAddress} />
      <meta property="og:locality" content={site.siteMetadata.locality} />
      <meta property="og:region" content={site.siteMetadata.region} />
      <meta property="og:postal_code" content={site.siteMetadata.postalCode} />
      <meta property="og:country_name" content={site.siteMetadata.countryName} />
      <meta property="og:email" content={site.siteMetadata.email} />
      <meta property="og:phone_number" content={site.siteMetadata.phoneNumber} />
      {/* site open graph attributes */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      {/* twitter card attributes */}
      <meta name="twitter:site" content="@GreenCourse44" />
      <meta name="twitter:creator" content="@GreenCourse44" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={site.siteMetadata.title} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {props.children}
    </Helmet>
  );
};

export default SEO;
