import React from 'react';
import { Helmet } from 'react-helmet';

const googleTagManagerCode = 'GTM-KMG5CNVV';

declare global {
  interface Window {
    dataLayer?: any[];
    tackingTag?: (...args: any[]) => void;
  }
}

interface TrackingContextType {
  init?: () => void;
  tag?: (...args: any[]) => void;
}

const TrackingContext = React.createContext<TrackingContextType>({});

export const TrackingProvider: React.FC = ({ children }) => {
  const [isInitialized, setInitialized] = React.useState(
    typeof window !== 'undefined' && typeof window?.dataLayer !== 'undefined'
  );

  const initTracking = React.useCallback(() => {
    window.dataLayer = window.dataLayer || [];
    window.tackingTag = function (...args: any[]) {
      /* istanbul ignore else */
      if (window.dataLayer) window.dataLayer.push(args);
    };

    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    setInitialized(true);
  }, [setInitialized]);

  const values = React.useMemo(
    () => ({
      init: initTracking,
      tag: isInitialized ? window.tackingTag : undefined,
    }),
    [initTracking, isInitialized]
  );

  React.useEffect(() => {
    if (!isInitialized) initTracking();
  }, [initTracking, isInitialized]);

  return (
    <React.Fragment>
      <TrackingContext.Provider value={values}>{children}</TrackingContext.Provider>
      {isInitialized && (
        <Helmet>
          {/*<!-- Start Google Tag Manger -->*/}
          <script
            async
            src={`https://www.googletagmanager.com/gtm.js?id=${googleTagManagerCode}`}
          ></script>
          {/*<!--  End Google Tag Manager -->*/}
        </Helmet>
      )}
    </React.Fragment>
  );
};

export function useTrackingContext(): TrackingContextType {
  return React.useContext(TrackingContext);
}
